import React from 'react'
import { StarIcon } from "@heroicons/react/24/solid"

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export const Rating = ({ rating }) => {
  return (
    <div className='flex'>
      {
        [0, 1, 2, 3, 4].map((rate) => {
          return (
            <StarIcon key={rate} className={classNames(rating > rate ? 'text-yellow-400' : 'text-gray-300', 'flex-shrink-0 h-5 w-5')} aria-hidden="true" />
          )
        })
      }
    </div>
  )
}
