import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useDispatch, useSelector } from 'react-redux'
import { removeWishlistProduct } from '../stores/WishlistSlice'
import { addProductInShoppingCart, addProductToShoppingCart } from '../stores/ShoppingCart'
import { v4 as uuidv4 } from 'uuid'
import { setCurrentProduct } from '../stores/ProductSlice'

export const Wishlist = () => {

    const products = useSelector((state) => state.wishlist.wishlistProducts)
    const allProducts = useSelector((state) => state.product.products)
    const loggedInStatus = useSelector((state) => state.user.loggedInStatus)
    const userDetails = useSelector((state) => state.user.userDetails)
    const [wishlistProducts, setWishlistProducts] = useState([])
    const [outOfStockItems, setOutOfStockItems] = useState([])

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const remove = (product) => {
        const wishlist = products.find((prod) => prod.productId === product.productId)
        dispatch(removeWishlistProduct(wishlist.wishlistId))
    }

    const addProduct = (product) => {
        dispatch(addProductToShoppingCart(product))
        remove(product)
        let postData = {
            productId: product.productId
        }
        if (loggedInStatus) {
            postData = {
                cartId: uuidv4(),
                userId: userDetails.userId,
                productId: product.productId,
                quantity: 1
            }
        }
        dispatch(addProductInShoppingCart(postData, loggedInStatus))
    }

    useEffect(() => {
        const wishlistIds = products.map(product => product.productId)
        const filterProducts = allProducts.filter((product) => wishlistIds.includes(product.productId) && product.productStock > 0)
        const outOfStock = allProducts.filter((product) => wishlistIds.includes(product.productId) && product.productStock <= 0)
        setWishlistProducts(filterProducts)
        setOutOfStockItems(outOfStock)
    }, [allProducts, products])

    return (
        <div className="bg-white mx-4 xs:mx-16 2xl:mx-32 py-16 sm:py-24">
            <div className="pt-16 pb-[7.25rem]">
                <div className="mx-auto sm:px-2 lg:px-8">
                    <div className="mx-auto px-4 lg:px-0">
                        <h1 className="text-3xl max-lg:text-start font-extrabold font-mono tracking-tight text-gray-900 sm:text-5xl">Wishlist</h1>
                    </div>
                </div>
                <div className={[wishlistProducts.length && 'pt-10']}>
                    <div className="">
                            {wishlistProducts.length === 0 && <div className='flex py-32 flex-col space-y-5 items-center justify-center'>
                                <h2 className='text-xl font-semibold'>Your Wishlist is Empty!!!</h2>
                                <button className='text-white bg-black p-5 w-fit px-10 hover:bg-[#b26b53] transition-all ease-in-out duration-500' onClick={() => navigate('/en-uk')}>Go back to Home Page</button>
                            </div>}
                        <div className="grid lg:grid-cols-2 2xl:grid-cols-3 mx-auto gap-6 lg:gap-12 gap-x-20 sm:px-4 lg:px-0">
                            {wishlistProducts.map((product) => {
                                return (
                                    <div key={product.productId} className="grid grid-cols-12 group border-gray-300 shadow-sm rounded-lg border-2">                                        
                                        <div className='col-span-4'>
                                            <div className="flex items-center group-hover:cursor-pointer bg-white w-full h-full rounded-lg overflow-hidden" onClick={() => {dispatch(setCurrentProduct(product)); navigate(`/en-uk/product/${product.productId}`)}}>
                                                <img src={product.productImages[0].imageSrc} alt={product.productImages[0].imageAlt} className="w-full h-full max-h-28 px-3" />
                                            </div>
                                        </div>
                                        <div className='col-span-8 p-4 relative flex items-center justify-start'>
                                            <div className="font-medium space-y-3 text-start text-gray-900">
                                                <h5 className='text-2xl group-hover:cursor-pointer group-hover:underline' onClick={() => {dispatch(setCurrentProduct(product)); navigate(`/en-uk/product/${product.productId}`)}}>
                                                    {product.productName.length > 20 ? `${product.productName.slice(0, 20)} ...` : product.productName }
                                                </h5>
                                                <p className='text-gray-500 font-medium text-sm'>
                                                    {product.productShortDescription}
                                                </p>
                                                <div className="mt-2 flex space-x-10 sm:mt-0">
                                                    <p className="flex items-center">
                                                        {product.productPrice}
                                                    </p>
                                                    <div className='flex space-x-1 items-center'>
                                                    {
                                                            product.productStock > 0 ? <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" /> :
                                                            <XMarkIcon className="flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />
                                                        }
                                                        
                                                        <span>{product.productStock > 0 ? 'In stock' : 'Out of Stock'}</span>
                                                    </div>
                                                </div>
                                                <div className='flex space-x-8 mt-5'>
                                                    <span className="text-red-600 opacity-100 cursor-pointer whitespace-nowrap hover:text-red-500" onClick={() => remove(product)}>Remove</span>
                                                    <span className="text-indigo-600 whitespace-nowrap cursor-pointer hover:text-indigo-500" onClick={() => addProduct()}>Add to Cart</span>
                                                    {/* <Link to="#" className="text-indigo-600 whitespace-nowrap hover:text-indigo-500">Buy now</Link> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                )
                            })}
                        </div >
                    </div >
                </div >
                {
                    outOfStockItems.length > 0 && <div className="mt-16">
                    <h2 className='text-start sm:ml-8 text-2xl font-medium mt-24 mb-6'>Out of Stock Items</h2>
                    <div className="mx-auto sm:px-2 lg:px-8">
                        <div className="grid lg:grid-cols-2 2xl:grid-cols-3 mx-auto gap-12 gap-x-20 sm:px-4 lg:px-0">
                            {outOfStockItems.map((product) => {
                                return (
                                    <div key={product.productId} className="grid grid-cols-12 border-gray-400 shadow-sm rounded-lg border-2">
                                        <div className='col-span-4  opacity-40'>
                                            <div className="flex items-center bg-white w-full h-full rounded-lg overflow-hidden">
                                                <img src={product.productImages[0].imageSrc} alt="product.imageAlt" className="w-full h-full max-h-28" />
                                            </div>
                                        </div>
                                        <div className='col-span-8 p-4 flex items-center justify-start'>
                                            <div className="font-medium space-y-3 text-start text-gray-900">
                                                <h5 className='text-2xl  opacity-40'>
                                                    {product.productName.length > 20 ? `${product.productName.slice(0, 20)} ...` : product.productName }
                                                </h5>
                                                <p className='text-gray-500 font-medium text-sm opacity-40'>
                                                    {product.productShortDescription}
                                                </p>
                                                <div className="mt-2 flex space-x-10 sm:mt-0 opacity-40">
                                                    <p className="flex items-center">
                                                        {product.productPrice}
                                                    </p>
                                                    <div className='flex space-x-1 items-center opacity-40'>
                                                    {
                                                            product.productStock > 0 ? <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" /> :
                                                            <XMarkIcon className="flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />
                                                        }
                                                        
                                                        <span>{product.productStock > 0 ? 'In stock' : 'Out of Stock'}</span>
                                                    </div>
                                                </div>
                                                <div className='flex space-x-8 mt-5'>
                                                    <span className="text-red-600 cursor-pointer opacity-100 whitespace-nowrap hover:text-red-500" onClick={() => remove(product)}>Remove</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div >
                                )
                            })}
                        </div >
                    </div >
                    </div >
                }
            </div >
        </div >
    )
}
