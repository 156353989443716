import React from 'react'

export const AboutUs = () => {
    return (
        <div className='px-40 tracking-wider text-start space-y-10 my-36 flex flex-col justify-start items-start'>
            <div className="mx-auto px-4 lg:px-0 mb-12">
                <h1 className="text-3xl font-extrabold font-mono tracking-tight text-gray-900 sm:text-5xl">About us</h1>
            </div>
            <div className='space-y-3'>
                <span className='text-2xl'>
                    <strong>About Gift Realm</strong>
                </span>
                <p>Welcome to Gift Realm, your ultimate destination for unique and thoughtful gifts for all occasions. At Gift Realm, we believe in the power of gifting to bring joy, express love, and create lasting memories. Our curated selection of handpicked gifts is designed to inspire and delight, making your moments even more special.</p>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'>
                    <strong>Our Mission</strong>
                </p>
                <p>At Gift Realm, our mission is to make gift-giving a truly meaningful experience. We understand that each gift carries sentiments and emotions, which is why we are dedicated to offering a diverse range of products that cater to every taste and preference. Whether it&#8217;s a birthday, anniversary, holiday, or any other celebration, we aim to help you find the perfect gift that reflects your thoughtfulness and care.</p>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'>
                    <strong>What We Offer</strong>
                </p>
                <ul className='text-start'>
                    <li className='ml-20 list-disc'><strong>Curated Collection:</strong> Our team scours the market to bring you a curated collection of gifts that stand out. From personalized keepsakes to trending gadgets, we have something for everyone.</li>
                    <li className='ml-20 list-disc'><strong>Quality and Craftsmanship:</strong> We are committed to quality. Many of our products are crafted by skilled artisans, ensuring that each gift is a masterpiece of craftsmanship and attention to detail.</li>
                    <li className='ml-20 list-disc'><strong>Thoughtful Gifting:</strong> We believe that the best gifts are those that resonate with the recipient. That&#8217;s why we focus on helping you discover gifts that align with the interests and passions of your loved ones.</li>
                </ul>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'><strong>Our Team</strong></p>
                <p>Behind Gift Realm is a team of passionate individuals who are dedicated to making your gifting experience exceptional. From our diligent curators who search for unique products to our customer support team that is always ready to assist, every member of our team plays a vital role in ensuring your satisfaction.</p>
            </div>
        </div>
    )
}
