import { Fragment, useState, useRef } from 'react'
import { IoEye, IoEyeOff } from "react-icons/io5";
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Link } from 'react-router-dom';
import { fetchUserData, addUser } from '../stores/UserSlice';
import { useDispatch } from 'react-redux';
import { showMessage } from '../helper';
import { Dialog, Transition, TransitionChild, DialogPanel } from '@headlessui/react'
import { LuLoader2 } from 'react-icons/lu';
import {v4 as uuidv4} from "uuid";
import { XMarkIcon } from '@heroicons/react/24/solid';
import ForgotPasswordModal from './ForgotPasswordModal';

export const Login = ({ closeModal }) => {
    const baseCredentials = { userName: '', userEmail: '', userPassword: '', userType: 'user' }
    const [credentials, setCredentials] = useState(baseCredentials)
    const [loading, setLoading] = useState(false)
    const [showForgotPassword, setShowForgotPassword] = useState(false)
    const [isRegister, setIsRegister] = useState(false)
    const [showPassword, setShowPassword] = useState(false)
    const [open, setOpen] = useState(true)

    const dispatch = useDispatch()

    const handleSubmit = async (e) => {
        setLoading(true)
        e.preventDefault();
        try {
            if (!isRegister) {
                dispatch(fetchUserData(credentials)).then((a) => {
                    setLoading(false)
                    if (a === 'Invalid Credentials') {
                        console.log('heyyy')
                        showMessage(a, 'error')
                        return;
                    }
                    
                    localStorage.setItem("user", JSON.stringify(a))
                    localStorage.setItem("loggedInStatus", true)
                    setCredentials(baseCredentials)
                    closeModal()
                })
            } else {
                credentials['userId'] = uuidv4()
                dispatch(addUser(credentials)).then((a) => {
                    setLoading(false)
                    if (a === 'Success') {
                        setIsRegister(false)
                        setCredentials(baseCredentials)
                        showMessage("Registered", 'success')
                        // setLoading(true)
                        setCredentials(baseCredentials)
                        return;
                    } else {
                        showMessage(a, 'error')
                    }
                })
            }
        } catch (error) {
            showMessage('Invalid Credentials', 'error')
            setLoading(false)
        }
    };

    const handleChange = (event) => {
        setCredentials({ ...credentials, [event.target.name]: event.target.value })
    }

    const cancelButtonRef = useRef(null)

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => { setOpen(); closeModal() }}>
                <TransitionChild
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </TransitionChild>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full justify-center text-center items-center p-0">
                        <TransitionChild
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-0 scale-95"
                            enterTo="opacity-100 translate-y-0 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 scale-100"
                            leaveTo="opacity-0 translate-y-0 scale-95"
                        >
                            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                                <div className='flex items-end justify-end mt-4 mr-4'>
                                    <XMarkIcon className='w-5 h-5 cursor-pointer' onClick={() => closeModal()}/>
                                </div>
                                <div className="flex min-h-full flex-col justify-center p-6 pb-12 lg:px-8">
                                    <div className="sm:mx-auto sm:w-full sm:max-w-sm">
                                        <img className="mx-auto h-10 w-auto" src="https://www.giftrealm.co.uk/images/logo.png" alt="Gift Realm" />
                                        <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">Sign in to your account</h2>
                                    </div>

                                    <div className="mt-10 sm:mx-auto sm:w-full text-start sm:max-w-sm">
                                        <form className="space-y-6" onSubmit={handleSubmit}>
                                            {isRegister && <div>
                                                <label htmlFor="userName" className="block text-lg font-medium leading-6 text-gray-900">Full Name</label>
                                                <div className="mt-2">
                                                    <input id="userName" name="userName" type="text" value={credentials.userName} required className="block w-full rounded-md border-0 p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base outline-none sm:leading-6" onChange={handleChange} />
                                                </div>
                                            </div>}
                                            <div>
                                                <label htmlFor="email" className="block text-lg font-medium leading-6 text-gray-900">Email address</label>
                                                <div className="mt-2">
                                                    <input id="email" name="userEmail" type="email" value={credentials.userEmail} required className="block w-full rounded-md border-0 p-3 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base outline-none sm:leading-6" onChange={handleChange} />
                                                </div>
                                            </div>
                                            {showForgotPassword && <ForgotPasswordModal closeModal={() => setShowForgotPassword(false)} />}
                                            <div>
                                                <label htmlFor="password" className="block text-lg font-medium leading-6 text-gray-900">Password</label>
                                                <div className="mt-2 w-full rounded-lg border-0 p-3 items-center flex text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base outline-none sm:leading-6">
                                                    <input id="password" name="userPassword" type={showPassword ? "text" : "password"} value={credentials.userPassword} required className="w-[90%] bg-transparent outline-none" onChange={handleChange} />
                                                    <button type='button' className='w-[10%] text-2xl' onClick={() => setShowPassword(!showPassword)}>
                                                        {showPassword ? (
                                                            <IoEyeOff className='text-black' />
                                                        ) : (
                                                            <IoEye className='text-black' />
                                                        )}
                                                    </button>
                                                </div>
                                                {!isRegister && <div className="text-sm mt-2 text-end">
                                                    <span onClick={() => setShowForgotPassword(true)} className="cursor-pointer font-semibold text-base text-indigo-600 hover:text-indigo-500">Forgot password?</span>
                                                </div>}
                                            </div>

                                            <div>
                                                <button type="submit" className="flex w-full justify-center rounded-lg bg-indigo-600 p-3 mt-16 text-lg font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">{!isRegister ? 'Sign in' : 'Register'} {loading && <LuLoader2 className='animate-spin w-6 h-6 ml-3'/>}</button>
                                            </div>
                                        </form>

                                        <p className="mt-10 text-center text-base text-gray-500">
                                            {!isRegister ? 'Not a member?' : 'Already a member?'}
                                            <button className="font-semibold ml-1 leading-6 text-indigo-600 hover:text-indigo-500" onClick={() => {setIsRegister(!isRegister); setCredentials(baseCredentials)}}>{!isRegister ? 'Register Now' : 'Sign In'}</button>
                                        </p>
                                    </div>
                                </div>
                            </DialogPanel>
                        </TransitionChild>
                    </div>
                    <ToastContainer />
                </div>
            </Dialog>
        </Transition.Root>
    )
}
