import React from 'react'
import { Link } from 'react-router-dom'

export const ShippingPolicy = () => {
    return (
        <div className='px-40 tracking-wider text-start space-y-10 my-36 flex flex-col justify-start items-start'>
            <div className="mx-auto px-4 lg:px-0 mb-12">
                <h1 className="text-3xl font-extrabold font-mono tracking-tight text-gray-900 sm:text-5xl">Shipping Policy</h1>
            </div>
            <div className='space-y-3'>
                <span className='text-2xl'>
                    <strong>Shipping Policy for Gift Realm</strong>
                </span>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'>
                    <strong>1. Shipping Methods</strong>
                </p>
                <p>We offer a variety of shipping methods to ensure that your gifts reach you in a timely manner. The available options may vary based on your location and the products you’ve selected.</p>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'>
                    <strong>2. Processing Time</strong>
                </p>
                <ul className='text-start'>
                    <li className='ml-20 list-disc'><strong>Order Processing:</strong> After your order is placed, it will undergo processing before shipment. Processing times may vary based on the products ordered. Please refer to the product description for estimated processing times.</li>
                </ul>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'>
                    <strong>3. Shipping Time</strong>
                </p>
                <ul className='text-start'>
                    <li className='ml-20 list-disc'><strong>Domestic Shipping:</strong> For domestic orders, shipping times typically range from 5 to 7 business days. Please note that these times are estimated and actual delivery times may vary.</li>
                    <li className='ml-20 list-disc'><strong>International Shipping:</strong> International orders may take longer to arrive due to customs procedures and varying international shipping regulations. Shipping times for international orders typically range from 2 to 3 weeks.</li>
                </ul>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'>
                    <strong>4. Shipping Costs</strong>
                </p>
                <ul className='text-start'>
                    <li className='ml-20 list-disc'>Shipping costs are calculated based on factors such as order weight, destination, and chosen shipping method. The exact shipping cost will be displayed during the checkout process.
                    </li>
                </ul>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'>
                    <strong>5. Tracking</strong>
                </p>
                <ul className='text-start'>
                    <li className='ml-20 list-disc'>Once your order is shipped, you will receive a tracking number via email. You can use this tracking number to monitor the status and location of your package.</li>
                </ul>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'>
                    <strong>6. Customs and Import Duties</strong>
                </p>
                <ul className='text-start'>
                    <li className='ml-20 list-disc'>For international orders, customers are responsible for any customs duties, taxes, or import fees that may apply in their country. Gift Realm is not responsible for any additional charges incurred during customs clearance.</li>
                </ul>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'>
                    <strong>7. Address Accuracy</strong>
                </p>
                <ul className='text-start'>
                    <li className='ml-20 list-disc'>Please ensure that your shipping address is accurate and complete to avoid delays or misdelivery. We cannot be held responsible for packages that are misdelivered due to incorrect addresses provided by the customer.</li>
                </ul>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'>
                    <strong>8. Delivery Issues</strong>
                </p>
                <ul className='text-start'>
                    <li className='ml-20 list-disc'>If you experience any issues with the delivery of your order, please contact our customer support team at <Link to="/en-uk/contactUs" className='underline font-bold'>contact us</Link>. We will do our best to assist you in resolving the matter.</li>
                </ul>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'><strong>9. Returns and Exchanges</strong></p>
                <ul className='text-start'>
                    <li className='ml-20 list-disc'>For information on returns and exchanges related to shipping, please refer to our <Link to="/en-uk/returnPolicy" className='underline font-bold'>Returns & Exchanges Policy</Link>.</li>
                </ul>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'><strong>10. Contact Us</strong></p>
                <p>If you have any questions or concerns about this Privacy Policy, please <Link to="/en-uk/contactUs" className='underline font-bold'>contact us</Link>. </p>

                <p>Remember, this is a general template and should be customized to reflect the specific shipping practices and policies of your website, “Gift Realm.” If you’re uncertain about legal requirements or specific details, it’s a good idea to consult with a legal professional or someone familiar with e-commerce regulations.</p>
            </div>
        </div>
    )
}
