import React from 'react'
import { Link } from 'react-router-dom'

export const TermsAndConditions = () => {
    return (
        <div className='px-40 tracking-wider text-start space-y-10 my-36 flex flex-col justify-start items-start'>
            <div className="mx-auto px-4 lg:px-0 mb-12">
                <h1 className="text-3xl font-extrabold font-mono tracking-tight text-gray-900 sm:text-5xl">Terms & Conditions</h1>
            </div>
            <div className='space-y-3'>
                <span className='text-2xl'>
                    <strong>Terms & Conditions for Gift Realm</strong>
                </span>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'>
                    <strong>1. Acceptance of Terms</strong>
                </p>
                <p>Welcome to Gift Realm. These Terms & Conditions outline the rules and regulations for the use of our website. By accessing or using our website, you agree to comply with these terms. If you disagree with any part of these terms, please do not use our website.</p>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'>
                    <strong>2. Use of Website</strong>
                </p>
                <ul className='text-start'>
                    <li className='ml-20 list-disc'>You must be at least 13 years old to use this website.</li>
                    <li className='ml-20 list-disc'>You are responsible for ensuring that your use of this website complies with applicable laws and regulations.</li>
                    <li className='ml-20 list-disc'>You agree not to use our website for any illegal or unauthorized purposes.</li>
                </ul>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'>
                    <strong>3. Intellectual Property</strong>
                </p>
                <ul className='text-start'>
                    <li className='ml-20 list-disc'>The content on this website, including text, images, logos, and graphics, is protected by intellectual property laws and is the property of Gift Realm.</li>
                    <li className='ml-20 list-disc'>You may not reproduce, distribute, modify, or create derivative works of our content without explicit permission.</li>
                </ul>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'>
                    <strong>4. User Accounts</strong>
                </p>
                <ul className='text-start'>
                    <li className='ml-20 list-disc'>If you create an account on our website, you are responsible for maintaining the confidentiality of your account information and password.</li>
                    <li className='ml-20 list-disc'>You agree to provide accurate and current information during account registration.</li>
                </ul>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'>
                    <strong>5. Privacy</strong>
                </p>
                <ul className='text-start'>
                    <li className='ml-20 list-disc'>Your use of our website is also governed by our Privacy Policy. Please review it to understand how we collect, use, and protect your information.</li>
                </ul>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'>
                    <strong>6. Limitation of Liability</strong>
                </p>
                <ul className='text-start'>
                    <li className='ml-20 list-disc'>Gift Realm is not liable for any damages resulting from the use or inability to use our website.</li>
                    <li className='ml-20 list-disc'>We are not responsible for any third-party content or websites linked from our website.</li>
                </ul>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'>
                    <strong>7. Changes to Terms</strong>
                </p>
                <ul className='text-start'>
                    <li className='ml-20 list-disc'>We reserve the right to update or change these Terms & Conditions at any time.</li>
                    <li className='ml-20 list-disc'>Any changes will be posted on our website and will be effective immediately upon posting.</li>
                </ul>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'>
                    <strong>8. Termination</strong>
                </p>
                <ul className='text-start'>
                    <li className='ml-20 list-disc'>We may terminate your access to the website at our discretion, without prior notice, for violations of these Terms & Conditions.</li>
                </ul>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'><strong>9. Governing Law</strong></p>
                <ul className='text-start'>
                    <li className='ml-20 list-disc'>These Terms & Conditions are governed by and interpreted in accordance with the laws of [your jurisdiction].</li>
                </ul>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'><strong>10. Contact Us</strong></p>
                <p>If you have any questions or concerns about this Privacy Policy, please <Link to="/en-uk/contactUs" className='underline font-bold'>contact us</Link>. </p>

                <p>Remember, it’s crucial to customize the terms and conditions to fit the specific nature of your website and business practices. If you have any legal concerns or questions, it’s a good idea to consult with a legal professional to ensure that your terms and conditions are compliant with relevant laws and regulations.</p>
            </div>
        </div>
    )
}
