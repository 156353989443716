import React from 'react'

export const ProgressBar = ({progressList, progressPercentage}) => {
  return (
    <div>
        <h4 className="sr-only">Status</h4>
        <div className="mt-6" aria-hidden="true">
            <div className="bg-gray-200 rounded-full overflow-hidden">
                <div className="h-2 bg-indigo-600 rounded-full" style={{ width: `${progressPercentage}` }} />
            </div>
            <div className={[progressList.length === 4 ? "hidden sm:grid grid-cols-4 text-sm font-medium text-gray-600 mt-6" : 'hidden sm:grid grid-cols-5 text-sm font-medium text-gray-600 mt-6']}>
                {
                    progressList.map((progress) => {
                        return (
                            <div key={progress.name} className={[progress.status ? 'text-indigo-600 text-right' : 'text-right']}>{progress.name}</div>
                        )
                    })
                }
            </div>
        </div>
    </div>
  )
}
