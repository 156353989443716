import { createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { headers, categoryAPI, homePageAPI } from '../APIRoutes'
import { showMessage } from '../helper'

const initialState = {
  currentCategory: {},
  categories: [],
  homePageData: {}
}

export const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCurrentCategory: (state, action) => {
      state.currentCategory = action.payload
    },
    setCategories: (state, action) => {
      state.categories = action.payload
    },
    setHomePageData: (state, action) => {
      state.homePageData = action.payload
    }
  },
})

export const fetchAllCategories = () => {
  return async (dispatch) => {
    try {
      const postData = {
        type: 'getAllCategories'
      }
      const response = await axios.post(categoryAPI, JSON.stringify(postData), { headers });
      const data = response.data;

      dispatch(setCategories(data.data));
    } catch (error) {
      showMessage(error, 'error');
    }
  };
};

export const fetchCategoryById = (id) => {
  return async (dispatch) => {
    try {

      const postData = {
        type: 'getCategoryById',
        id: id
      }
      const response = await axios.post(categoryAPI, JSON.stringify(postData), { headers })
      const data = response.data
      dispatch(setCurrentCategory(data.data[0]))
      
    } catch (error) {
      showMessage(error, 'error')
    }
  }
}

export const fetchHomePageData = () => {
  return async (dispatch) => {
    try {
      const postData = {
        type: 'getHomePage'
      }
      const response = await axios.post(homePageAPI, JSON.stringify(postData), { headers })
      if (response.data.data) {
        dispatch(setHomePageData(response.data.data[0]))
      }
    } catch (error) {
      showMessage(error, 'error')
    }
  }
}

// Action creators are generated for each case reducer function
export const { setCurrentCategory, setCategories, setHomePageData } = categorySlice.actions

export default categorySlice.reducer