import { createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { usersAPI, headers } from '../APIRoutes'
import { showMessage } from '../helper'

const initialState = {
  loggedInStatus: false,
  userDetails: {},
  addressDetails: []
}

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateLoggedInStatus: (state, action) => {
      state.loggedInStatus = action.payload
    },
    updateUserDetails: (state, action) => {
      state.userDetails = action.payload
    },
    updateAddresses: (state, action) => {
      state.addressDetails = action.payload
    }
  },
})

export const addUser = (credentials) => {
  return async (dispatch) => {
    try {
      const postData = {
        type: 'addUser',
        ...credentials
      }

      const response = await axios.post(usersAPI, JSON.stringify(postData), { headers })
      const data = response.data.data;

      if (data === 'Success') {
        dispatch(updateUserDetails(credentials))
      }

      return data
    } catch (error) {
      showMessage(error, 'error')
    }
  }
}

export const fetchUserData = (credentials) => {
  return async (dispatch) => {
    try {
      const postData = {
        type: 'getUser',
        userEmail: credentials.userEmail
      }
      const response1 = await axios.post(usersAPI, JSON.stringify(postData), { headers });
      const data = response1.data.data;
      if (data[0].userId && data[0].userPassword === credentials.userPassword) {
        const userDetails = data[0]
        delete userDetails.userPassword
        dispatch(updateUserDetails(userDetails))
        dispatch(updateLoggedInStatus(true))
        return userDetails
      } else return "Invalid Credentials"

    } catch (error) {
      showMessage(error, 'error');
    }
  };
};

export const fetchLoggedInStatus = () => {
    return (dispatch) => {
        try {
          const isUserLoggedIn = localStorage.getItem('loggedInStatus')
          if (isUserLoggedIn === 'true') {
            const credentials = JSON.parse(localStorage.getItem('user'))
            dispatch(updateUserDetails(credentials))
            dispatch(updateLoggedInStatus(true))
            return true
          }
          return false
        } catch (error) {
            showMessage(error, 'error')
            return false
        }
    }
}

export const addAddress = (addressDetails) => {
  return async (dispatch) => {
    try {
      const postdata = {
        type: 'addAddress',
        ...addressDetails
      }
      await axios.post(usersAPI, postdata, { headers })
    } catch (error) {
      showMessage(error, 'error')
    }
  }
}

export const getAddress = (userId) => {
  return async (dispatch) => {
    try {
      const postdata = {
        type: 'getAddress',
        userId: userId
      }
      const data = await axios.post(usersAPI, postdata, { headers })
      if (data.data.data !== 'Address Not found') dispatch(updateAddresses(data.data.data))
    } catch (error) {
      showMessage(error, 'error')
    }
  }
}

// Action creators are generated for each case reducer function
export const { updateLoggedInStatus, updateUserDetails, updateAddresses } = userSlice.actions

export default userSlice.reducer