import { createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import { ordersAPI, headers } from '../APIRoutes';
import { showMessage } from '../helper';

const initialState = {
  orders: []
}

export const orderSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {
    updateOrder: (state, action) => {
      state.orders = action.payload
    }
  },
})

export const fetchOrders = (userId) => {
  return async (dispatch) => {
    try {
      const postData = {
        type: 'getOrders',
        userId: userId
      }
      const response = await axios.post(ordersAPI, JSON.stringify(postData), { headers });
      const data = response.data;

      dispatch(updateOrder(data.data));
    } catch (error) {
      showMessage(error, 'error');
    }
  };
};

export const addOrder = (orderInfo) => {
  return async (dispatch) => {
    try {
      const postData = {
        type: 'addOrder',
        ...orderInfo
      }

      const response = await axios.post(ordersAPI, JSON.stringify(postData), { headers });
      const data = response.data;
      

    } catch (error) {
      showMessage(error, 'error')
    }
  }
}

// Action creators are generated for each case reducer function
export const { updateOrder } = orderSlice.actions

export default orderSlice.reducer