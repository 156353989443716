import React, { useState } from "react";
import { GrSend } from "react-icons/gr";
import axios from "axios";
import { usersAPI, headers } from "../APIRoutes";
import { showMessage } from "../helper";
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Link } from "react-router-dom";
import Logo from "../assets/images/logo.png"
import { ArrowRightIcon } from "@heroicons/react/24/solid";

export default function Footer () {
    const [email, setEmail] = useState('')

    function validateEmail(email) {
        // Regular expression pattern for validating email
        const pattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    
        // Test the email against the pattern
        return pattern.test(email);
    }

    const setNewsLetter = async () => {
        if (email !== '' && validateEmail(email)) {
            const postData = {
                type: "addNewsLetter",
                emailId: email
            }

            await axios.post(usersAPI, JSON.stringify(postData), { headers }).then((a) => {
                if (a.data.data === "Success") {
                    showMessage("You've successfully subscribed the newsletter", 'success')
                    setEmail('')
                } else if (a.data.data === "Email exists") showMessage("You are already subscribed", "error")
            })
        } else {
            showMessage('Please fill your email correctly', 'error')
        }
    }

    return (
        <div className="text-white bg-[#212121]">
            <div className="grid grid-cols-12 pt-28 px-4 md:px-20 lg:space-x-10 lg:px-10 xl:px-20 2xl:px-40">
                <div className="col-span-full sm:col-span-6 lg:col-span-3 flex flex-col items-start space-y-5">
                    <img src={Logo} alt="logo" className="h-20" />
                    {/* <p className="text-start text-3xl">Gift Realm</p> */}
                    <p className="w-64 text-start break-word">Gift Realm is your one-stop online destination for all things gifting.</p>
                </div>
                <div className="flex pt-16 sm:pt-0 flex-col space-y-5 col-span-full sm:col-span-6 lg:col-span-3">
                    <p className="flex items-start text-2xl">POLICIES</p>
                    <div className="flex flex-col space-y-3 items-start">
                        <Link className="hover:underline hover:scale-x-105 hover:translate-x-11 duration-300 flex gap-x-5 group" to="/en-uk/privacyPolicy"><ArrowRightIcon className="w-5 h-5 -ml-10 hidden group-hover:block"/>Privacy Policy</Link>
                        <Link className="hover:underline hover:scale-x-105 hover:translate-x-11 duration-300 flex gap-x-5 group" to="/en-uk/refundAndReturnPolicy"><ArrowRightIcon className="w-5 h-5 -ml-10 hidden group-hover:block"/>Refund & Return Policy</Link>
                        <Link className="hover:underline hover:scale-x-105 hover:translate-x-11 duration-300 flex gap-x-5 group" to="/en-uk/shippingPolicy"><ArrowRightIcon className="w-5 h-5 -ml-10 hidden group-hover:block"/>Shipping Policy</Link>
                        <Link className="hover:underline hover:scale-x-105 hover:translate-x-11 duration-300 flex gap-x-5 group" to="/en-uk/termsAndConditions"><ArrowRightIcon className="w-5 h-5 -ml-10 hidden group-hover:block"/>Terms & Condition</Link>
                    </div>
                </div>
                <div className="flex pt-16 lg:pt-0 flex-col space-y-5 col-span-full sm:col-span-6 lg:col-span-3">
                    <p className="flex items-start text-2xl">INFORMATION</p>
                    <div className="flex flex-col space-y-3 items-start">
                        <Link className="hover:underline hover:scale-x-105 hover:translate-x-11 duration-300 flex gap-x-5 group" to="/en-uk"><ArrowRightIcon className="w-5 h-5 -ml-10 hidden group-hover:block"/>Home</Link>
                        <Link className="hover:underline hover:scale-x-105 hover:translate-x-11 duration-300 flex gap-x-5 group" to="/en-uk/aboutUs"><ArrowRightIcon className="w-5 h-5 -ml-10 hidden group-hover:block"/>About Us</Link>
                        <Link className="hover:underline hover:scale-x-105 hover:translate-x-11 duration-300 flex gap-x-5 group" to="/en-uk/contactUs"><ArrowRightIcon className="w-5 h-5 -ml-10 hidden group-hover:block"/>Contact Us</Link>
                    </div>
                </div>
                <div className="flex pt-16 lg:pt-0 col-span-full sm:col-span-6 lg:col-span-3 flex-col items-start">
                    <p className="text-2xl text-start">Sign Up Newsletter​</p>
                    <p className="text-start text-sm mt-7">Subscribe to the weekly newsletter for all the latest updates emerce online shop give you the best online e-commerce</p>
                    <div className="w-[100%] mt-6 h-12 flex items-center bg-white">
                        <input type="email" className="w-[90%] h-full p-3 text-black focus:outline-none" placeholder="Enter your Email" required value={email} onChange={(e) => setEmail(e.target.value)}/>
                        <span className="h-full flex items-center w-[15%] px-2">
                            <GrSend className="text-black w-6 h-6 hover:text-[#b26b53] hover:rotate-45 cursor-pointer transition-all ease-in-out duration-500" onClick={() => setNewsLetter()}/>
                        </span>
                    </div>
                </div>
                <ToastContainer />
            </div>
            <div className="pb-8 pt-20">
                <p>Copyright © 2024 <Link className="underline hover:text-[#b26b53] duration-300" to="/en-uk">Gift Realm</Link>. All rights reserved.</p>
            </div>
        </div>
    )
}