import { configureStore } from '@reduxjs/toolkit'
import categoryReducer from '../src/stores/CategorySlice'
import wishlistReducer from '../src/stores/WishlistSlice'
import userReducer from '../src/stores/UserSlice'
import productReducer from '../src/stores/ProductSlice'
import shoppinCartReducer from '../src/stores/ShoppingCart'
import ordersReducer from '../src/stores/OrderSlice'

export const store = configureStore({
  reducer: {
    category: categoryReducer,
    wishlist: wishlistReducer,
    user: userReducer,
    product: productReducer,
    shoppingCart: shoppinCartReducer,
    orders: ordersReducer
  },
})