import React, { useEffect } from 'react'
import { useState } from 'react'
import { Radio, RadioGroup } from '@headlessui/react'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { useSelector, useDispatch } from 'react-redux'
import { updateSelectedAddress } from '../stores/ShoppingCart'
import postalCodes from "../assets/json/postcodes.json"
import ComboBox from './Combobox'

export const AddressDetails = () => {

  const userDetails = useSelector((state) => state.user.userDetails)
  const userAddresses = useSelector((state) => state.user.addressDetails)

  const dispatch = useDispatch()

  const defaultAddress = { fullName: '', phoneNumber: '', addressId: 'new', userId: userDetails.userId, firstAddressLine: '', secondAddressLine: '', landMark: '', pincode: '' }
  
  const [addresses, setAddresses] = useState([])
  const [selected, setSelected] = useState(userAddresses[0] || defaultAddress)
  const [addNewAddress, setAddNewAddress] = useState(false)
  const [newAddress, setNewAddress] = useState(defaultAddress)
  const [filteredPostalCodes, setFilteredPostalCodes] = useState(postalCodes.slice(0, 50))

  const handleChange = (event) => {
    setNewAddress({...newAddress, [event.target.name]: event.target.value})
    dispatch(updateSelectedAddress(newAddress))
  }
  
  const changePostalCode = (code) => {
    setNewAddress({...newAddress, pincode: code})
    dispatch(updateSelectedAddress({...newAddress, pincode: code}))
  }

  const filterPostalCodes = (query) => {
    const filteredCodes = postalCodes.filter((person) =>
      person
        .toLowerCase()
        .replace(/\s+/g, '')
        .includes(query.toLowerCase().replace(/\s+/g, ''))
    )
    setFilteredPostalCodes(filteredCodes.slice(0, 50))
  }

  useEffect(() => {
    if (selected.addressId === 'new') {
      setAddNewAddress(true)
    } else {
      setAddNewAddress(false)
      setNewAddress(defaultAddress)
    }
    dispatch(updateSelectedAddress(selected))
  }, [selected])

  useEffect(() => {
      setAddresses([...userAddresses, defaultAddress])
  }, [userAddresses])

  return (
    <div>
      <form>
        <div className="grid grid-cols-12 mt-12 gap-x-8 text-start">
          <div className='col-span-12'>
            <label htmlFor="addressId" className="block ml-2 text-lg font-semibold leading-6 text-gray-900">
              Select Address
            </label>
            <div className="mt-2.5">
              <RadioGroup by="addressId" value={selected} onChange={setSelected} aria-label="Server size" className="space-y-2">
                {addresses.map((plan) => (
                  <Radio
                    key={plan.addressId}
                    value={plan}
                    className={["group relative opacity-50 border-2 hover:opacity-70 border-dashed border-gray-400 flex cursor-pointer rounded-lg bg-white/5 py-4 px-5 shadow-md transition focus:outline-none data-[focus]:outline-1 data-[focus]:outline-white data-[checked]:bg-white/10 data-[checked]:opacity-100"]}
                  >
                    <div className="flex w-full items-center justify-between">
                      <div className="text-sm/6">
                        {plan.addressId === 'new' ? <p className="font-semibold text-base">Add New Address</p> :
                        <p className="font-semibold text-base">{plan.fullName} {plan.phoneNumber && `,  ${plan.phoneNumber}`}</p>}
                        <div className='pt-2'>
                          <div>{plan.firstAddressLine}</div>
                          <div>{plan.secondAddressLine}</div>
                          <div>{plan.landMark}</div>
                        </div>
                        <div>{plan.pincode}</div>
                      </div>
                      <CheckCircleIcon className="size-6 fill-indigo-600 opacity-0 transition group-data-[checked]:opacity-100" />
                    </div>
                  </Radio>
                ))}
              </RadioGroup>
            </div >
          </div >
        </div >
        {addNewAddress && <div className='grid grid-cols-12 mt-12 gap-x-8 gap-y-4 mb-10 text-start'>
        <div className='col-span-6 mb-5'>
            <label htmlFor="name" className="block ml-2 text-lg font-semibold leading-6 text-gray-900">
              Full name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                onChange={(e) => handleChange(e)}
                value={newAddress.fullName}
                name="fullName"
                id="first-name"
                autoComplete="given-name"
                className="block w-full text-lg rounded-md border-0 px-3.5 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-none  sm:leading-6"
              />
            </div>
          </div>
          <div className='col-span-6'>
            <label htmlFor="phoneNumber" className="block ml-2 text-lg font-semibold leading-6 text-gray-900">
              Phone number
            </label>
            <div className="flex w-full p-1 text-lg rounded-md h-[3.3rem] border text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-none sm:leading-6 mt-2.5">
              {/* <div className="w-[20%] inset-y-0 left-0 border-r border-gray-300 flex items-center justify-center">
                +44
              </div> */}
              <input
                type="number"
                onChange={(e) => handleChange(e)}
                value={newAddress.phoneNumber}
                name="phoneNumber"
                id="phoneNumber"
                autoComplete="tel"
                className="w-full px-2.5 outline-none"
              />
            </div>
          </div>
          <div className='col-span-6'>
            <label htmlFor="addressId" className="block ml-2 text-lg font-semibold leading-6 text-gray-900">
              First Line
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                onChange={(e) => handleChange(e)}
                value={newAddress.firstAddressLine}
                name="firstAddressLine"
                id="first-name"
                autoComplete="given-name"
                className="block w-full text-lg rounded-md border-0 px-3.5 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-none  sm:leading-6"
              />
            </div>
          </div>
          <div className='col-span-6'>
            <label htmlFor="addressId" className="block ml-2 text-lg font-semibold leading-6 text-gray-900">
              Second Line
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                onChange={(e) => handleChange(e)}
                value={newAddress.secondAddressLine}
                name="secondAddressLine"
                id="first-name"
                autoComplete="given-name"
                className="block w-full text-lg rounded-md border-0 px-3.5 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-none  sm:leading-6"
              />
            </div>
          </div>
          <div className='col-span-6'>
            <label htmlFor="addressId" className="block ml-2 text-lg font-semibold leading-6 text-gray-900">
              Land Mark
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                onChange={(e) => handleChange(e)}
                value={newAddress.landMark}
                name="landMark"
                id="first-name"
                autoComplete="given-name"
                className="block w-full text-lg rounded-md border-0 px-3.5 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-none  sm:leading-6"
              />
            </div>
          </div>
          <div className='col-span-6'>
            <label htmlFor="addressId" className="block ml-2 text-lg font-semibold leading-6 text-gray-900">
              Pincode
            </label>
            <div className="mt-2.5">
              <ComboBox items={filteredPostalCodes} selectedItem={changePostalCode} displayItem={newAddress.pincode} placeholder="Select Postal Code" filterSearch={filterPostalCodes}/>
              {/* <input
                type="text"
                onChange={(e) => handleChange(e)}
                value={newAddress.pincode}
                name="pincode"
                id="first-name"
                autoComplete="given-name"
                className="block w-full text-lg rounded-md border-0 px-3.5 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset outline-none  sm:leading-6"
              /> */}
            </div>
          </div>
        </div>
        }
      </form >
    </div >
  )
}
