import React from 'react'
import { GrInstagram, GrTwitter } from 'react-icons/gr'
import { LuFacebook } from 'react-icons/lu'
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/solid"

export const TopNavbar = () => {
  return (
    <div className='text-white hidden lg:flex bg-[#212121] h-12 px-32 xl:px-52 items-center justify-between fixed top-0 w-full z-50'>
        <div className='flex gap-x-6 justify-center items-center'>
            <PhoneIcon className='h-5 w-5' />
            <span className='-ml-5' >+454-55455449</span>
            <PhoneIcon className='h-5 w-5' />
            <span className='-ml-5'>+566-65656565</span>
            <EnvelopeIcon className='w-5 h-5' />
            <span className='-ml-5'>info@giftrealm.com</span>
        </div>
        <div className='flex space-x-6'>
            <GrInstagram className='text-white cursor-pointer w-5 h-5 hover:text-red-400' />
            <GrTwitter className='text-white cursor-pointer w-5 h-5' />
            <LuFacebook className='text-white hover:text-blue-600 hover:fill-blue-600 cursor-pointer w-5 h-5' />
        </div>
    </div>
  )
}
