import React, { useEffect, useState } from "react";
import Banner from "../assets/images/01_homepage_candle_05_1920x.png";
import Return from "../assets/images/return.png"
import Delivery from "../assets/images/delivery.png"
import Support from "../assets/images/callSupport.png"
import Checkout from "../assets/images/secureCheckout.png"
import { MdOutlineCurrencyPound } from "react-icons/md";
import { ArrowLongRightIcon, ArrowRightIcon, HeartIcon, ShoppingBagIcon } from "@heroicons/react/24/outline"
import { HeartIcon as SolidHeartIcon, ShoppingBagIcon as SolidShoppingBagIcon } from "@heroicons/react/24/solid"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux'
import { setCurrentCategory } from "../stores/CategorySlice";
import { addProductInWishlist, addProductToWishlist, removeWishlistProduct } from "../stores/WishlistSlice";
import { Rating } from "../components/Rating";
import { v4 as uuidv4 } from "uuid";
import { setCurrentProduct } from "../stores/ProductSlice";
import { addProductInShoppingCart, addProductToShoppingCart, removeCartProduct } from "../stores/ShoppingCart";
import { Login } from "../components/Login";

export default function HomePage() {

    const wishlistProducts = useSelector((state) => state.wishlist.wishlistProducts)
    const homePageData = useSelector((state) => state.category.homePageData)
    const userDetails = useSelector((state) => state.user.userDetails)
    const categories = useSelector((state) => state.category.categories)
    const allProducts = useSelector((state) => state.product.products)
    const loggedInStatus = useSelector((state) => state.user.loggedInStatus)
    const [showLoginModal, setShowLoginModal] = useState(false)
    const shoppingCartProducts = useSelector((state) => state.shoppingCart.shoppingCartProducts)

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [homePageObject, setHomePageObject] = useState({})
    
    const navigateToCategory = (category) => {
        dispatch(setCurrentCategory(category))
        navigate(`/en-uk/category/${category.id}`)
    }
    
    const handleWishlist = (event, id, type) => {
        event.stopPropagation();
        if (!loggedInStatus) {
            setShowLoginModal(true);
            return;
        }
        const product = allProducts.find((product) => product.productId === id)
        if (type === 'add') {
            dispatch(addProductToWishlist(product))
            dispatch(addProductInWishlist({
                wishlistId: uuidv4(),
                userId: userDetails.userId,
                productId: product.productId
            }))
        }
        else {
            const wishlist = wishlistProducts.find((prod) => prod.productId === product.productId)
            dispatch(removeWishlistProduct(wishlist.wishlistId))
        }
    }

    const handleCart = (e, product, type) => {
        e.stopPropagation();
        if (type === 'add') {
            dispatch(addProductToShoppingCart(product))
            let postData = {
                productId: product.productId
            }
            if (loggedInStatus) {
                postData = {
                    cartId: uuidv4(),
                    userId: userDetails.userId,
                    productId: product.productId,
                    quantity: 1
                }
            }
            dispatch(addProductInShoppingCart(postData, loggedInStatus))
        } else {
            dispatch(removeCartProduct(product, loggedInStatus))
        }
    }
    
    const openProduct = (id, product) => {
        dispatch(setCurrentProduct(product))
        navigate(`/en-uk/product/${id}`)
    }
    
    const calculateTimeLeft = () => {
        if (homePageObject.dealOfTheDay) {
            const targetDateTime = homePageObject.dealOfTheDay.time
            const now = new Date();
            const target = new Date(targetDateTime);
            const difference = target - now;
            
            let timeLeft = {};
            
            if (difference > 0) {
                timeLeft = {
                    hours: Math.floor((difference / (1000 * 60 * 60))),
                    minutes: Math.floor((difference / 1000 / 60) % 60),
                    seconds: Math.floor((difference / 1000) % 60)
                };
            }
            
            return timeLeft;
        } 
        return {}
    };
    
    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());
    
    useEffect(() => {
        if (homePageData && allProducts && categories) {
            const productList = homePageData.topProducts ? homePageData.topProducts.split('|') : []
            const categoryList = homePageData.topCategories ? homePageData.topCategories.split('|') : []
            const deals = homePageData.dealOfTheDay ? homePageData.dealOfTheDay.split('|') : []
            const data = {
                banner: homePageData.banner,
                topCategories: categories.filter((category) => categoryList.includes(category.id)),
                topProducts: allProducts.filter((product) => productList.includes(product.productId)),
                dealOfTheDay: {
                    product: allProducts.find((product) => product.productId === deals[0]),
                    time: deals[1]
                }
            }

            data.topCategories = data.topCategories.map((category) => {
                const products = allProducts.filter((product) => product.productCategoryId === category.id)
                return {
                    ...category,
                    totalProducts: products.length
                }
            })
            setHomePageObject(data)
        }

    }, [homePageData, allProducts, categories])

    useEffect(() => {
        const timer = setInterval(() => {
          setTimeLeft(calculateTimeLeft());
        }, 1000);
    
        return () => clearInterval(timer);
      }, [homePageObject]);

    return (
        <div className="space-y-20 lg:space-y-40 mb-20">
            {showLoginModal && <Login closeModal={() => setShowLoginModal(false)}/>}
            {/* Top Banner */}
            <div className="w-full mt-20">
                <img src={homePageObject.banner || Banner} alt="homepage banner" className="w-[100%] object-cover" />
            </div>
            {/* Category Section */}
            <div className="mx-4 xs:mx-16 2xl:mx-32  grid grid-cols-12 md:space-x-10 xl:space-x-5 2xl:space-x-10 mb-10 2xl:max-w-[90rem]">
                <div className="col-span-full md:col-span-6 xl:col-span-3 flex flex-col items-start">
                    <span className="font-custom text-custom text-7xl tracking-wide font-medium">Categories</span>
                    <span className="tracking-widest text-4xl leading-10 text-start">Our Bestsellers</span>
                    <p className="text-start mt-10 text-gray-500">Experience the epitome of elegance with our luxury gifts. Featuring designer accessories and gourmet treats, these gifts are perfect for those who appreciate the finer things in life.</p>
                </div>
                {
                    homePageObject.topCategories && homePageObject.topCategories.map((category) => {
                        return (
                            <div key={category.id} className="max-md:mt-16 col-span-full md:col-span-6 xl:col-span-3 cursor-pointer group" onClick={() => navigateToCategory(category)}>
                                <div className="flex justify-center items-center">
                                    <img src={category.imageSrc} alt={category.imageAlt} className="rounded-full object-fit w-72 h-72" />
                                </div>
                                <div className="flex mt-12 justify-center items-center">
                                    <p className="text-xl tracking-wider">{category.name}</p>
                                </div>
                                <div className="flex justify-center group-hover:translate-x-36 transition-all duration-75 ease-in-out space-x-20 items-center">
                                    <p className="mt-1 -ml-40 group-hover:-ml-32 opacity-0 group-hover:opacity-100 flex items-center justify-center gap-x-3 group-hover:underline">
                                        View All <ArrowLongRightIcon className="w-5 h-5"/>
                                    </p>
                                    <p className="text-md mt-1 group-hover:opacity-0 text-gray-600">
                                        {category.totalProducts} items
                                    </p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>

            {/* Deals of the day */}
            {timeLeft.hours !== undefined && homePageObject.dealOfTheDay && homePageObject.dealOfTheDay.product && <div className="bg-[#FAF1ED] py-16 mb-10 grid grid-cols-12">
                <div className="mx-4 xl:ml-20 2xl:ml-48 flex col-span-full lg:col-span-6 md:-ml-20 lg:ml-10 md:justify-center lg:justify-start">
                    <img src={homePageObject.dealOfTheDay.product.productImages[0].imageSrc} alt={homePageObject.dealOfTheDay.product.productImages[0].imageSrc} className="bg-transparent image-circle rounded-full min-h-72 max-h-[35rem]"/>
                    <div className="bg-white max-xs:text-base text-black -ml-32 xs:-ml-52 cost-circle rounded-t-full w-16 h-16 xs:w-24 xs:h-24 rounded-br-full text-lg flex items-center justify-center font-semibold">
                        <MdOutlineCurrencyPound className="w-5 h-5 xs:w-6 xs:h-6 text-black font-semibold"/> {homePageObject.dealOfTheDay.product.productDiscount}
                    </div>
                </div>
                <div className="space-y-10 xl:ml-32 mx-4 xs:mx-16 mt-10 xl:mt-20 lg:mx-10 xl:mx-0 max-lg:justify-center col-span-full lg:col-span-6">
                    <h1 className="text-5xl text-start text-gray-800">Special Deal!</h1>
                    <p className="xl:w-[32rem] text-start text-gray-500 text-base">{homePageObject.dealOfTheDay.product.productLongDescription}</p>
                    <h3 className="text-start text-5xl text-custom py-6">
                        <p>
                            {timeLeft.hours} : {timeLeft.minutes} : {timeLeft.seconds}
                        </p>    
                    </h3>
                    <button className="text-white bg-black p-5 px-10 hover:bg-[#b26b53] transition-all ease-in-out duration-500 flex items-center justify-start" onClick={() => openProduct(homePageObject.dealOfTheDay.product.productId, homePageObject.dealOfTheDay.product)}>
                        <p className="w-fit mr-5 text-lg">View Product</p>
                        <ArrowRightIcon className="w-8 h-8" />
                    </button>
                </div>
            </div>}

            {/* Information */}
            <div className="mx-4 xs:mx-16 2xl:mx-32 grid grid-cols-12 my-10">
                <div className="pb-8 lg:pb-0 border-b-2 lg:border-b-0 flex items-center col-span-full sm:col-span-6 lg:col-span-3 sm:border-r-2 justify-center flex-col">
                    {/* <div className="flex items-center flex-col"> */}
                        <img src={Return} alt="return" className="h-12 w-12" />
                        <p className="text-black font-light mt-6 tracking-widest text-xl">Free Easy Returns</p>
                        <p className="text-gray-500 mt-1 text-sm">Within 30 days for an exchange</p>
                    {/* </div> */}
                </div>
                <div className="pb-8 pt-8 lg:pt-0 lg:pb-0 border-b-2 lg:border-b-0 flex items-center col-span-full sm:col-span-6 lg:col-span-3 lg:border-r-2 justify-center flex-col">
                    <img src={Delivery} alt="return" className="h-12 w-16" />
                    <p className="text-black font-light mt-6 tracking-widest text-xl">Free Delivery</p>
                    <p className="text-gray-500 mt-1 text-sm flex items-center">On orders Over <MdOutlineCurrencyPound className="w-4 h-4 text-gray-500" /> 90</p>
                </div>
                <div className="pt-8 lg:pt-0 border-b-2 sm:border-b-0 pb-8 lg:pb-0 flex items-center col-span-full sm:col-span-6 lg:col-span-3 sm:border-r-2 justify-center flex-col">
                    <img src={Support} alt="return" className="h-12 w-12" />
                    <p className="text-black font-light mt-6 tracking-widest text-xl">All Day Call Support</p>
                    <p className="text-gray-500 mt-1 text-sm">24/7 Support care</p>
                </div>
                <div className="pt-8 lg:pt-0 flex items-center col-span-full sm:col-span-6 lg:col-span-3 justify-center flex-col">
                    <img src={Checkout} alt="return" className="h-12 w-12" />
                    <p className="text-black font-light mt-6 tracking-widest text-xl">Secure Checkout</p>
                    <p className="text-gray-500 mt-1 text-sm">Pay with Multiple Credit Cards</p>
                </div>
            </div>

            {/* Best Items */}
            <div>
                <div className="grid grid-cols-1 gap-x-12 gap-y-16 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-24 mx-4 xs:mx-16 2xl:mx-32">
                    <div className="flex text-start flex-col items-start">
                        <span className="font-custom text-custom text-6xl">Best Items</span>
                        <span className="tracking-widest text-4xl leading-10">Our Bestsellers</span>
                        <p className="text-start mt-10 text-gray-500">Discover our top gifts, including personalized keepsakes and elegant accessories. Each item is selected for its quality and thoughtful design, perfect for making any occasion special.</p>
                    </div>
                    {
                        homePageObject.topProducts && homePageObject.topProducts.map((bestProduct) => {
                            return (
                                <div key={bestProduct.productId} className="group cursor-pointer">
                                    <div className="relative aspect-h-1 z-0 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7" onClick={() => openProduct(bestProduct.productId, bestProduct)}>
                                        <img
                                            src={bestProduct.productImages[0].imageSrc}
                                            alt={bestProduct.productImages[0].imageAlt}
                                            className="h-56 w-full bg-white  object-contain group-hover:opacity-75"
                                        />
                                        <div className="absolute z-10 top-5 space-y-3 right-5 bg-white px-2 py-3 hidden group-hover:block">
                                            {
                                                wishlistProducts.find((product) => product.productId === bestProduct.productId) ?
                                                    <SolidHeartIcon className="w-7 h-7 hover:scale-110 text-red-600" onClick={(event) => { handleWishlist(event, bestProduct.productId, 'remove') }} /> :
                                                    <HeartIcon className="w-7 h-7 hover:scale-110" onClick={(event) => { handleWishlist(event, bestProduct.productId, 'add') }} />
                                            }
                                            {
                                                shoppingCartProducts.find((product) => product.productId === bestProduct.productId) ? 
                                                <SolidShoppingBagIcon className='w-7 h-7 hover:scale-110' onClick={(event) => handleCart(event, bestProduct, 'remove')} /> :
                                                <ShoppingBagIcon className="w-7 h-7 hover:scale-110" onClick={(event) => handleCart(event, bestProduct, 'add')}/>
                                            }
                                        </div>
                                    </div>
                                    <h3 className="mt-4 text-md w-fit text-gray-700">{bestProduct.productName}</h3>
                                    <div className="grid grid-cols-2 mt-2">
                                        <div className="flex items-center">
                                            <MdOutlineCurrencyPound className="w-6 h-6 text-gray-900" />
                                            <p className="text-lg font-medium text-gray-900">{bestProduct.productPrice}</p>
                                        </div>
                                        <div className="flex justify-end items-center">
                                            <Rating rating={bestProduct.productReviewRating} />
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                {/* <div className="flex justify-center mt-16">
                    <button className="text-white bg-black hover:bg-[#b26b53] transition-all duration-500 ease-in-out p-2 px-8 flex items-center justify-start">
                        <p className="w-24 text-md">Show More</p>
                        <ArrowRightIcon className="w-8 h-8" />
                    </button>
                </div> */}
            </div>

            {/* Top Reviews */}
            <section className="mx-4 xs:mx-16 2xl:mx-32 ">
                <div className="max-w-7xl mx-auto md:grid md:grid-cols-2 md:px-6 lg:px-8">
                    <div className="py-12 px-4 sm:px-6 md:flex md:flex-col md:py-16 md:pl-0 md:pr-10 md:border-r lg:pr-16">
                        <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
                            <div className="relative text-lg font-medium  md:flex-grow">
                                <svg className="absolute top-0 -left-4 transform -translate-x-3 -translate-y-2 h-8 w-8 " fill="currentColor" viewBox="0 0 32 32" aria-hidden="true">
                                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                                </svg>
                                <p className="relative">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo expedita voluptas culpa sapiente alias molestiae. Numquam corrupti in laborum sed rerum et corporis.
                                </p>
                            </div>
                            <footer className="mt-8">
                                <div className="flex items-start">
                                    <div className="ml-4">
                                        <div className="text-base font-medium ">Judith Black</div>
                                        <div className="text-base font-medium">CEO, Tuple</div>
                                    </div>
                                </div>
                            </footer>
                        </blockquote>
                    </div>
                    <div className="py-12 px-4 border-t-2 sm:px-6 md:py-16 md:pr-0 md:pl-10 md:border-t-0 md:border-l lg:pl-16">
                        <blockquote className="mt-6 md:flex-grow md:flex md:flex-col">
                            <div className="relative text-lg font-medium  md:flex-grow">
                                <svg className="absolute top-0 -left-4 transform -translate-x-3 -translate-y-2 h-8 w-8 " fill="currentColor" viewBox="0 0 32 32">
                                    <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                                </svg>
                                <p className="relative">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo expedita voluptas culpa sapiente alias molestiae. Numquam corrupti in laborum sed rerum et corporis. Nemo expedita voluptas culpa sapiente alias molestiae.
                                </p>
                            </div>
                            <footer className="mt-8">
                                <div className="flex items-start">
                                    <div className="ml-4">
                                        <div className="text-base font-medium ">Joseph Rodriguez</div>
                                        <div className="text-base font-medium ">CEO, Workcation</div>
                                    </div>
                                </div>
                            </footer>
                        </blockquote>
                    </div>
                </div>
            </section>
        </div>
    )
}