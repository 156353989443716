import { useEffect, useState } from 'react'
import { MdOutlineCurrencyPound } from "react-icons/md";
import { HeartIcon, ShoppingBagIcon } from "@heroicons/react/24/outline"
import { useSelector, useDispatch } from "react-redux"
import { Rating } from '../components/Rating';
import { useNavigate } from 'react-router-dom';
import { Reviews } from '../components/Reviews';
import { fetchProductById, setCurrentProduct } from '../stores/ProductSlice';
import { useLocation } from 'react-router-dom';
import { addProductInWishlist, addProductToWishlist } from '../stores/WishlistSlice';
import { v4 as uuidv4 } from 'uuid';
import { addProductInShoppingCart, addProductToShoppingCart } from '../stores/ShoppingCart';
import { showMessage } from '../helper';
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { Login } from '../components/Login';

export default function Product() {

    const product = useSelector((state) => state.product.currentProduct)
    const allProducts = useSelector((state) => state.product.products)
    const allWishlist = useSelector((state) => state.wishlist.wishlistProducts)
    const allShoppingCartProducts = useSelector((state) => state.shoppingCart.shoppingCartProducts)
    const loggedInStatus = useSelector((state) => state.user.loggedInStatus)
    const currentUser = useSelector((state) => state.user.userDetails)
    const [selectedImage, setSelectedImage] = useState({})
    const [relatedProducts, setRelatedProducts] = useState([])
    const [showLoginModal, setShowLoginModal] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()

    const toggleWishlist = (wishlistProduct) => {
        if (!loggedInStatus) {
            setShowLoginModal(true);
            return;
        }
        const filtered = allWishlist.find((wishlist) => wishlist.productId === product.productId)
        if (!filtered) {
            const postData = {
                wishlistId: uuidv4(),
                productId: wishlistProduct.productId,
                userId: currentUser.userId
            }
            dispatch(addProductToWishlist(product))
            dispatch(addProductInWishlist(postData))
        } else {
            showMessage('This product is already been wishlisted by you', 'success')
        }
    }

    const navigateToProduct = (product) => {
        dispatch(setCurrentProduct(product))
        navigate(`/en-uk/product/${product.productId}`)
    }

    const addCart = (product) => {
        dispatch(addProductToShoppingCart(product))
        let postData = {
            productId: product.productId
        }
        if (loggedInStatus) {
            postData = {
                cartId: uuidv4(),
                userId: currentUser.userId,
                productId: product.productId,
                quantity: 1
            }
        }
        dispatch(addProductInShoppingCart(postData, loggedInStatus))
    }

    useEffect(() => {
        if (!Object.keys(product).includes('productId')) {
            const productId = location.pathname.split('/')[3]
            dispatch(fetchProductById(productId))
        }
    }, [location.pathname, dispatch, product])

    useEffect(() => {
        const filteredProducts = allProducts.filter((prod) => prod.productCategoryId === product.productCategoryId && prod.productId !== product.productId)
        setRelatedProducts(filteredProducts.slice(0, 4))
    }, [product, allProducts])

    return (
        <div className="bg-white my-36 mx-4 xs:mx-16 2xl:mx-32">
            {showLoginModal && <Login closeModal={() => setShowLoginModal(false)}/>}
            <div className="flex justify-center">
                <div className='max-h-[70%] grid grid-cols-12 mt-10'>
                    <div className= 'col-span-full lg:col-span-6 flex flex-col-reverse xl:grid xl:grid-cols-12'>
                        <div className='col-span-full xl:col-span-3'>
                            {Object.keys(product).includes('productImages') && product.productImages.length && (
                                <div className='flex xl:flex-col pr-2 py-10 xl:py-2 max-xl:space-x-5 xl:space-y-5 overflow-y-auto max-h-[60vh] bg-transparent'>
                                    {
                                        product.productImages.map((image, idx) => {
                                            return (
                                                <div key={idx} className='min-w-32'>
                                                    <img src={image.imageSrc} alt={image.imageAlt} onClick={() => setSelectedImage(image)} className='w-36 h-32 border-2 rounded-lg cursor-pointer border-gray-400' />
                                                </div>
                                            )
                                        })
                                    }
                                </div>)}
                        </div>
                        <div className="col-span-full xl:col-span-9 h-[60vh] overflow-hidden rounded-lg lg:block ">
                            {selectedImage.imageSrc ? (
                                <img
                                    src={selectedImage.imageSrc}
                                    alt={selectedImage.imageAlt}
                                    className="h-full w-full rounded-lg"
                                />
                            ) : (
                                <img
                                    src={product.productImages && product.productImages.length > 0 && product.productImages[0].imageSrc}
                                    alt={product.productImages && product.productImages.length > 0 && product.productImages[0].imageAlt}
                                    className="h-full w-full rounded-lg"
                                />
                            )}
                        </div>

                    </div>
                    <div className='max-lg:pt-16 lg:p-5 lg:pl-10 xl:pl-20 col-span-full lg:col-span-6'>
                        <div className='grid text-start grid-cols-12 space-y-5'>
                            <div className='col-span-12 flex grid-cols-12 justify-between'>
                                <div className='col-span-10 font-medium text-4xl flex items-center'>
                                    {product.productName}
                                </div>
                            </div>
                            <div className='col-span-12 flex'>
                                <MdOutlineCurrencyPound className="w-6 h-6 mt-0.5 text-gray-500" />
                                <div className="flex text-gray-900 text-lg">
                                    <p className={product.productDiscount && "line-through"}>{product.productPrice}</p>
                                    <span className="ml-2">{product.productDiscount}</span>
                                </div>
                            </div>
                            <div className='col-span-12'>
                                <Rating rating={product.productReviewRating} />
                            </div>
                            <div className='col-span-12 text-gray-600'>
                                {product.productShortDescription}
                            </div>
                            <div className='col-span-12'>
                                <p className='text-xl font-medium'>Size</p>
                                <div className='flex space-x-2'>
                                    <p>{product.productSize}</p>
                                    <p>{product.productSizeType}</p>
                                </div>
                            </div>
                            <div className='col-span-12'>

                            </div>
                            <div className='flex col-span-12 space-x-2 xs:space-x-10'>
                                <button className="btn !px-1 min-[400px]:!px-3 xs:!px-5 text-base border-y-2 border-[#b26b53]">
                                    {
                                        allShoppingCartProducts.find((wishlist) => wishlist.productId === product.productId) ? 
                                        <span onClick={() => navigate('/en-uk/shoppingCart')}>Go To Cart</span> : <span onClick={() => addCart(product)}>Add To Cart</span>
                                    }
                                    <span className='xs:ml-2'><ShoppingBagIcon className='w-6 h-6' /></span>
                                </button>
                                <button className="btn !px-1 min-[400px]:!px-3 xs:!px-5  text-base border-y-2 border-[#b26b53]">
                                    <span onClick={() => toggleWishlist(product)}>Wishlist</span>
                                    <span className='xs:ml-2'><HeartIcon className='w-6 h-6' /></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='text-start'>
                <div className='text-2xl font-medium mt-20'>
                    Detailed Description
                </div>
                <div className='pt-4 break-words'>
                    {product.productLongDescription}
                </div>
            </div>
            {/* Reviews */}
            <div>
                <Reviews productId={product.productId} currentRating={product.productReviewRating || 0} />
            </div>
            {/* Related products */}
            <div className='flex justify-center'>
                <section aria-labelledby="related-heading" className="w-full">
                    <h2 id="related-heading" className="text-3xl font-medium text-start text-gray-900">Related Products</h2>

                    <div className="mt-6 grid grid-cols-12 gap-y-10 gap-x-6 xl:gap-x-10">
                        {
                            relatedProducts.map((relatedProduct) => {
                                return (
                                    <div key={relatedProduct.productId} className="group relative col-span-full sm:col-span-6 lg:col-span-4 xl:col-span-3 cursor-pointer px-4 py-4 border rounded-lg shadow-lg hover:scale-105 duration-500" onClick={() => navigateToProduct(relatedProduct)}>
                                        <div className="w-full min-h-80 aspect-w-1 aspect-h-1 rounded-md overflow-hidden group-hover:opacity-75 lg:h-80 lg:aspect-none">
                                            <img src={relatedProduct.productImages && relatedProduct.productImages[0].imageSrc} alt={relatedProduct.productImages && relatedProduct.productImages[0].imageAlt} className="w-full h-full object-center object-cover lg:w-full lg:h-full" />
                                        </div>
                                        <div className="mt-4 flex justify-between">
                                            <div>
                                                <h3 className="text-sm text-gray-900">
                                                    <p className='text-base text-start'>
                                                        <span aria-hidden="true" className="absolute inset-0" />
                                                        {relatedProduct.productName}
                                                    </p>
                                                </h3>
                                            </div>
                                            <div className="text-base font-medium text-gray-900 flex items-center">
                                                <MdOutlineCurrencyPound className='w-5 h-5'/>
                                                <div className="flex">
                                                    <p className={relatedProduct.productDiscount && "line-through"}>{relatedProduct.productPrice}</p>
                                                    <span className="ml-2">{relatedProduct.productDiscount}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div >
                </section >
            </div>
            <ToastContainer />
        </div >
    )
}
