import { StarIcon } from '@heroicons/react/24/solid'
import React, { useState, useEffect } from 'react'
import ReviewModal from './ReviewModal'
import axios from 'axios'
import { reviewsAPI, headers } from '../APIRoutes'
import { useSelector } from 'react-redux'
import { Login } from './Login'

export const Reviews = ({productId, currentRating}) => {
    const product = useSelector((state) => state.product.currentProduct)
    const userDetails = useSelector((state) => state.user.userDetails)
    const loggedInStatus = useSelector((state) => state.user.loggedInStatus)
    const [showLoginModal, setShowLoginModal] = useState(false)

    const baseReviews = {
        average: 0,
        totalCount: 0, 
        counts: [
            { rating: 5, count: 0 },
            { rating: 4, count: 0 },
            { rating: 3, count: 0 },
            { rating: 2, count: 0 },
            { rating: 1, count: 0 }
        ], 
        featured: []
    }
    const [reviews, setReviews] = useState(baseReviews)

    const [openReviewModal, setOpenReviewModal] = useState(false)

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    const fetchReviews = async () => {
        const response = await axios.post(reviewsAPI, JSON.stringify({type: 'getReviews',productId: productId}), { headers })
        const resp = response.data.data
        const counts = [
            { rating: 5, count: 0 },
            { rating: 4, count: 0 },
            { rating: 3, count: 0 },
            { rating: 2, count: 0 },
            { rating: 1, count: 0 }
        ]
        const featured = []
        let average = parseInt(product.productReviewRating || 0)
        if (resp !== 'Product Not found') {
            let totalReviews = 0
            setReviews(baseReviews)
            resp.forEach((review) => {
                counts.forEach((count) => {if (count.rating === parseInt(review.rate)) { count.count++; totalReviews += parseInt(review.rate) }})
                const feature = {
                    id: review.reviewId,
                    userId: review.userId,
                    rating: review.rate,
                    content: review.review,
                    author: review.reviewerName
                }
                featured.push(feature)
            })
            setReviews({average: Math.round(totalReviews / resp.length), totalCount: resp.length, counts: counts, featured: featured})
        }
        else setReviews(baseReviews)
    }

    const toggleReviewModal = (bool) => {
        if (!loggedInStatus) {
            setShowLoginModal(true);
            return;
        }
        setOpenReviewModal(bool)
        if (!bool) fetchReviews()
    }

    useEffect(() => {
        fetchReviews()
    }, [product])

    return (
        <div className="py-16 text-start sm:py-24 lg:pb-32 lg:grid lg:grid-cols-12 lg:gap-x-8">
            {showLoginModal && <Login closeModal={() => setShowLoginModal(false)}/>}
            <div className="lg:col-span-4">
                <h2 className="text-2xl font-extrabold tracking-tight text-gray-900">Customer Reviews</h2>

                <div className="mt-3 flex items-center">
                    <div className="flex items-center">
                        {
                            [0, 1, 2, 3, 4].map((rating) => {
                                return (
                                    <StarIcon key={rating} className={classNames(reviews.average > rating ? 'text-yellow-400' : 'text-gray-300', 'flex-shrink-0 h-5 w-5')} aria-hidden="true" />
                                )
                            })
                        }
                    </div>
                    <p className="ml-2 text-sm text-gray-900">Based on {reviews.totalCount} reviews</p>
                </div>

                <div className="mt-6">
                    <dl className="space-y-3">
                        {
                            reviews.counts.map((count) => {
                                return (
                                    <div key={count.rating} className="flex items-center text-sm">
                                        <dt className="flex-1 flex items-center">
                                            <div aria-hidden="true" className="ml-1 flex-1 flex items-center">
                                                <p className='mr-2'>{count.rating}</p>
                                                <StarIcon className={classNames(count.count > 0 ? 'text-yellow-400' : 'text-gray-300', 'flex-shrink-0 h-5 w-5')} aria-hidden="true" />
                                                <div className="ml-3 relative flex-1">
                                                    <div className="h-3 bg-gray-100 border border-gray-200 rounded-full" />
                                                    {count.count > 0 && <div className="absolute inset-y-0 bg-yellow-400 border border-yellow-400 rounded-full" style={{ width: `${Math.round((count.count / reviews.totalCount) * 100)}%` }} /> }
                                                </div>
                                            </div>
                                        </dt>
                                        <dd className="ml-3 w-10 text-right tabular-nums text-sm text-gray-900">{Math.round((count.count / reviews.totalCount) * 100) || 0}%</dd>
                                    </div>
                                )
                            })
                        }
                    </dl>
                </div>

                {reviews.featured.find((review) => review.userId === userDetails.userId) ? 
                <div className='mt-10'>
                    <h3 className='text-lg font-medium text-gray-900'>We have successfully got your review</h3>
                </div> 
                : <div className="mt-10">
                    <h3 className="text-lg font-medium text-gray-900">Share your thoughts</h3>
                    <p className="mt-1 text-sm text-gray-600">If you’ve used this product, share your thoughts with other customers</p>

                    <button className="mt-6 inline-flex w-full bg-white border border-gray-300 rounded-md py-2 px-8 items-center justify-center text-sm font-medium text-gray-900 hover:bg-gray-50 sm:w-auto lg:w-full" onClick={() => toggleReviewModal(true)}>Write a review</button>
                </div>}
                {openReviewModal && <ReviewModal closeModal={toggleReviewModal} productId={productId} currentRating={currentRating} handleSubmit={() => fetchReviews()}/>}
            </div>

            <div className="mt-16 lg:mt-0 lg:col-start-6 lg:col-span-7">
                    {!reviews.featured.length && <div className=' w-full flex justify-center items-center mt-24'>
                        <h3 className='text-lg text-gray-900 font-medium'>
                            Be the first one to share your thoughts, we value your valuable thoughts on this product
                        </h3>
                    </div>}
                <div className="flow-root">
                    <div className="-my-12 divide-y divide-gray-200">
                        {
                            reviews.featured.map((review) => {
                                return (
                                    <div key={review.id} className="py-12">
                                        <div className="flex items-center">
                                            <div>
                                                <h4 className="text-sm font-bold text-gray-900">{review.author}</h4>
                                                <div className="mt-1 flex items-center">
                                                    {
                                                        [0, 1, 2, 3, 4].map((rating) => {
                                                            return (
                                                                <StarIcon key={rating} className={classNames(review.rating > rating ? 'text-yellow-400' : 'text-gray-300', 'flex-shrink-0 h-5 w-5')} aria-hidden="true" />
                                                            )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <div className="mt-4 space-y-6 text-base text-start italic text-gray-600" >{review.content}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
