import { createSlice } from '@reduxjs/toolkit'
import axios from "axios"
import { headers, productAPI } from '../APIRoutes'
import { showMessage } from '../helper'

const initialState = {
  currentProduct: {},
  products: [],
  searchedProduct: {}
}

export const productSlice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    setCurrentProduct: (state, action) => {
      state.currentProduct = action.payload
    },
    setProducts: (state, action) => {
      state.products = action.payload
    },
    setSearchedProduct: (state, action) => {
      state.searchedProduct = action.payload
    }
  },
})

export const fetchAllProducts = (id) => {
  return async (dispatch) => {
    try {
      const postData = {
        type: 'getAllProducts',
      }
      if (id !== '') postData['categoryId'] = id
      const response = await axios.post(productAPI, JSON.stringify(postData), { headers });
      const data = response.data;

      dispatch(setProducts(data.data));
    } catch (error) {
      showMessage(error, 'error');
    }
  };
};

export const fetchProductById = (id) => {
  return async (dispatch) => {
    try {
      const postData = {
        type: 'getAllProducts',
        productId: id
      }
      const response = await axios.post(productAPI, JSON.stringify(postData), { headers });
      const data = response.data;

      dispatch(setCurrentProduct(data.data[0]));
    } catch (error) {
      showMessage(error, 'error');
    }
  };
};

export const updateProduct = (product) => {
  return async (dispatch) => {
    try {
      const postData = {
        type: 'updateProduct',
        ...product
      }

      await axios.post(productAPI, JSON.stringify(postData), { headers });

    } catch (error) {
      showMessage(error, 'error')
    }
  }
}

// Action creators are generated for each case reducer function
export const { setCurrentProduct, setProducts,setSearchedProduct } = productSlice.actions

export default productSlice.reducer