export const host = 'https://giftrealm.co.uk'

export const headers = {
    'Content-Type': 'application/json',
}

export const categoryAPI = `${host}/category_data`
export const contactsAPI = `${host}/contact_data`
export const productAPI = `${host}/product_data`
export const usersAPI = `${host}/users_data`
export const wishlistAPI = `${host}/wishlist`
export const shoppingCartAPI = `${host}/shopping_cart`
export const saveForLaterAPI = `${host}/saveForLater`
export const ordersAPI = `${host}/orders`
export const reviewsAPI = `${host}/reviews`
export const homePageAPI = `${host}/homepage`