import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"
import axios from "axios"
import { headers, usersAPI } from '../APIRoutes';
import { LuLoader2 } from 'react-icons/lu';
import { IoEye, IoEyeOff } from "react-icons/io5";
import { showMessage } from '../helper';

export default function ForgotPasswordModal({ closeModal }) {
    const [open, setOpen] = useState(true)
    const [showPassword, setShowPassword] = useState(false)
    const [showConfirmPassword, setShowConfirmPassword] = useState(false)
    const [loading, setLoading] = useState(false)
    const [showResetPassword, setShowResetPassword] = useState(false)
    const cancelButtonRef = useRef()
    const [credentials, setCredentials] = useState({ email: '', password: '', confirmPassword: '' })

    const handleInput = (e) => {
        setCredentials({ ...credentials, [e.target.name]: e.target.value })
    }

    const resetPassword = async (e) => {
        try {
            setLoading(true)
            if (!credentials.password) {
                showMessage('Please enter your password', 'error')
            }
            if (credentials.password === credentials.confirmPassword) {
                const postData = {
                    type: 'resetPassword',
                    email: credentials.email,
                    password: credentials.password
                }
                const response = await axios.post(usersAPI, JSON.stringify(postData), { headers })
                const data = response.data.data
                if (data === 'Success') {
                    showMessage('Password Updated', 'success')
                    setTimeout(() => closeModal(), 500)
                }
                else showMessage('Password Updation failed, Try again later', 'error')
            } else {
                showMessage('Password does not match', 'error')
            }
            setLoading(false)
        } catch (error) {
            showMessage(error, 'error')
        }
    }

    const verifyEmail = async () => {
        try {
            setLoading(true)
            const postData = {
                type: 'getUser',
                userEmail: credentials.email
            }
            const response = await axios.post(usersAPI, JSON.stringify(postData), { headers });
            const data = response.data.data
            if (data === 'User Not found') {
                showMessage('Email is not valid, Please check again', 'error')
            } else {
                setShowResetPassword(true)
            }
            setLoading(false)
        } catch (error) {
            showMessage(error, 'error')
        }
    }

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-10" static={true} initialFocus={cancelButtonRef} onClose={() => { setOpen(); closeModal() }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-2xl bg-white text-left shadow-xl transition-all">
                                <div className="bg-white p-4 pt-10 w-[30rem] px-10">
                                    <div className="flex w-[100%]">
                                        <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                                            <ExclamationTriangleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                                        </div>
                                        <div className="mt-3 text-center sm:ml-4 sm:mt-1.5 sm:text-left">
                                            <Dialog.Title as="h3" className="text-2xl font-semibold leading-6 text-gray-900">
                                                Forgot Password?
                                            </Dialog.Title>
                                            {!showResetPassword ? <ul className='flex flex-col space-y-1 my-10 items-start'>
                                                <p className='text-lg'>Email:</p>
                                                <input type="text" id='email' placeholder='Enter Email' name='email' value={credentials.email} className='block w-full rounded-md border-0 h-12 py-1.5 px-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-lg sm:leading-6' onChange={(e) => handleInput(e)} />
                                            </ul> : <div>
                                                <ul className='flex flex-col space-y-1 my-10 items-start'>
                                                    <p className='text-lg'>New Password:</p>
                                                    <div className="mt-2 w-full rounded-lg border-0 p-3 items-center flex text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base outline-none sm:leading-6">
                                                        <input id="password" name="password" placeholder='Enter Password' type={showPassword ? "text" : "password"} value={credentials.password} required className="w-[90%] bg-transparent outline-none" onChange={handleInput} />
                                                        <button type='button' className='w-[10%] text-2xl' onClick={() => setShowPassword(!showPassword)}>
                                                            {showPassword ? (
                                                                <IoEyeOff className='text-black' />
                                                            ) : (
                                                                <IoEye className='text-black' />
                                                            )}
                                                        </button>
                                                    </div>
                                                </ul>

                                                <ul className='flex flex-col space-y-1 my-10 items-start'>
                                                    <p className='text-lg'>Confirm Password:</p>
                                                    <div className="mt-2 w-full rounded-lg border-0 p-3 items-center flex text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-base outline-none sm:leading-6">
                                                        <input id="confirmPassword" name="confirmPassword" placeholder='Enter Password' type={showConfirmPassword ? "text" : "password"} value={credentials.confirmPassword} required className="w-[90%] bg-transparent outline-none" onChange={handleInput} />
                                                        <button type='button' className='w-[10%] text-2xl' onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                                                            {showConfirmPassword ? (
                                                                <IoEyeOff className='text-black' />
                                                            ) : (
                                                                <IoEye className='text-black' />
                                                            )}
                                                        </button>
                                                    </div>
                                                </ul>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="bg-gray-50 px-4 pb-10 justify-end flex space-x-5 sm:px-20">
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                                        onClick={() => closeModal()}
                                        ref={cancelButtonRef}
                                    >
                                        Cancel
                                    </button>

                                    {!showResetPassword ? <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                                        onClick={() => verifyEmail()}
                                    >
                                        Next
                                        {loading && <LuLoader2 className='animate-spin w-6 h-6 ml-3' />}
                                    </button> : <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 sm:w-auto"
                                        onClick={() => resetPassword()}
                                    >
                                        Reset Password
                                        {loading && <LuLoader2 className='animate-spin w-6 h-6 ml-3' />}
                                    </button>}

                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                    <ToastContainer />
                </div>
            </Dialog>
        </Transition.Root>
    )
}