import { createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import { shoppingCartAPI, headers, saveForLaterAPI } from '../APIRoutes';
import { v4 as uuidv4 } from "uuid"
import { showMessage } from '../helper';

const initialState = {
  shoppingCartProducts: [],
  saveForLater: [],
  selectedAddress: {}
}

export const shoppingCart = createSlice({
  name: 'shoppingCart',
  initialState,
  reducers: {
    addProductToShoppingCart: (state, action) => {
      state.shoppingCartProducts.push(action.payload)
    },
    removeProductFromShoppingCart: (state, action) => {
      const filteredShoppingCart = state.shoppingCartProducts.filter((product) => product.productId !== action.payload)
      state.shoppingCartProducts = filteredShoppingCart
    },
    updateShoppingCartProducts: (state, action) => {
      state.shoppingCartProducts = action.payload
    },
    addProductToSaveForLater: (state, action) => {
      state.saveForLater.push(action.payload)
    },
    removeProductFromSaveForLater: (state, action) => {
      const filteredSaveForLater = state.saveForLater.filter((product) => product.id !== action.payload)
      state.saveForLater = filteredSaveForLater
    },
    updateSaveForLater: (state, action) => {
      state.saveForLater = action.payload
    },
    updateSelectedAddress: (state, action) => {
      state.selectedAddress = action.payload
    }
  },
})

export const fetchShoppingCartProducts = (userId) => {
  return async (dispatch) => {
    try {
      let products = JSON.parse(localStorage.getItem('shoppingCart'))
      if (userId !== '') {
        const postData = {
          type: 'getProducts',
          userId: userId
        }
        const response = await axios.post(shoppingCartAPI, JSON.stringify(postData), { headers });
        const data = response.data;

        dispatch(updateShoppingCartProducts(data.data))

        if (products === null || products === undefined) return;

        const localStorageProducts = data.data.map((product) => product.productId)
        const filteredProducts = products.filter((product) => !localStorageProducts.includes(product.productId))
        dispatch(updateShoppingCartProducts([...data.data, ...filteredProducts]));

        filteredProducts.map(async (product) => {
          const postData = {
            type: 'addProduct',
            cartId: uuidv4(),
            userId: userId,
            ...product
          }
          await axios.post(shoppingCartAPI, JSON.stringify(postData), { headers })
        })
        localStorage.removeItem('shoppingCart')

      } else {
        if (products === null) {
          products = []
        }
        dispatch(updateShoppingCartProducts(products))
      }
    } catch (error) {
      showMessage(error, 'error');
    }
  };
};

export const addProductInShoppingCart = (product, isUserLoggedIn) => {
  return async (dispatch) => {
    try {
      const postData = {
        type: 'addProduct',
        ...product
      }

      if (isUserLoggedIn) {
        const response = await axios.post(shoppingCartAPI, JSON.stringify(postData), { headers });
        const data = response.data;
        dispatch(fetchShoppingCartProducts(product.userId))
      } else {
        let products = JSON.parse(localStorage.getItem('shoppingCart'))
        if (products === null) {
          products = []
        }
        products.push({ productId: product.productId, quantity: "1" })
        localStorage.setItem('shoppingCart', JSON.stringify(products))
      }

    } catch (error) {
      showMessage(error, 'error')
    }
  }
}

export const removeCartProduct = (product, isUserLoggedIn) => {
  return async (dispatch) => {
    try {
      if (isUserLoggedIn) {
        const postData = {
          type: 'deleteProduct',
          cartId: product.cartId
        }
        await axios.post(shoppingCartAPI, JSON.stringify(postData), { headers });
      } else {
        const products = JSON.parse(localStorage.getItem('shoppingCart'))
        const filteredProducts = products.filter((prod) => prod.productId !== product.productId)
        localStorage.setItem('shoppingCart', JSON.stringify(filteredProducts))
      }
      dispatch(removeProductFromShoppingCart(product.productId))
    } catch (error) {
      showMessage('error')
    }
  }
}

export const updateCartProduct = (product, isUserLoggedIn, type) => {
  return async (dispatch) => {
    try {
      const postData = {
        type: 'updateProduct',
        ...product
      }

      let products = []

      if (isUserLoggedIn) {
        products = product
        
        axios.post(shoppingCartAPI, JSON.stringify(postData), { headers })
        
      } else {
        products = JSON.parse(localStorage.getItem('shoppingCart'))
        products = products.map((prod) => {
          if(prod.productId === product.productId) {
            prod.quantity = product.quantity
          }
          return prod
        })
        // products.push({ productId: product.productId, quantity: 1 })
        localStorage.setItem('shoppingCart', JSON.stringify(products))
      }

    } catch (error) {
      showMessage(error, 'error')
    }
  }
}

export const fetchSaveForLater = (userId) => {
  return async (dispatch) => {
    try {
      const postData = {
        type: 'getData',
        userId: userId
      }
      const response = await axios.post(saveForLaterAPI, JSON.stringify(postData), { headers })
      const data = response.data;
      dispatch(updateSaveForLater(data.data))
    } catch (error) {
      showMessage(error, 'error')
    }
  }
}

export const addSaveForLater = (product) => {
  return async (dispatch) => {
    try {
      const postData = {
        type: 'addData',
        ...product
      }

      await axios.post(saveForLaterAPI, JSON.stringify(postData), { headers })
      dispatch(addProductToSaveForLater(product))
    } catch (error) {
      showMessage(error, 'error')
    }
  }
}

export const removeSaveForLater = (id) => {
  return async (dispatch) => {
    try {
      const postData = {
        type: 'deleteData',
        id: id,
      }

      dispatch(removeProductFromSaveForLater(id))
      await axios.post(saveForLaterAPI, JSON.stringify(postData), { headers })
    } catch (error) {
      showMessage(error, 'error')
    }
  }
}

// Action creators are generated for each case reducer function
export const { addProductToShoppingCart, removeProductFromShoppingCart, updateShoppingCartProducts, addProductToSaveForLater, removeProductFromSaveForLater, updateSaveForLater, updateSelectedAddress } = shoppingCart.actions

export default shoppingCart.reducer