import { createSlice } from '@reduxjs/toolkit'
import axios from "axios";
import { wishlistAPI, headers } from '../APIRoutes';
import { showMessage } from '../helper';

const initialState = {
  wishlistProducts: []
}

export const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState,
  reducers: {
    addProductToWishlist: (state, action) => {
      state.wishlistProducts.push(action.payload)
    },
    removeProductFromWishlist: (state, action) => {
      const filteredWishlist = state.wishlistProducts.filter((product) => product.wishlistId !== action.payload)
      state.wishlistProducts = filteredWishlist
    },
    updateWishlistProducts: (state, action) => {
      state.wishlistProducts = action.payload
    }
  },
})

export const fetchWishlistProducts = (userId) => {
  return async (dispatch) => {
    try {
      const postData = {
        type: 'getProducts',
        userId: userId
      }
      const response = await axios.post(wishlistAPI, JSON.stringify(postData), { headers });
      const data = response.data;

      dispatch(updateWishlistProducts(data.data));
    } catch (error) {
      showMessage(error, 'error');
    }
  };
};

export const addProductInWishlist = (product) => {
  return async (dispatch) => {
    try {
      const postData = {
        type: 'addProduct',
        ...product
      }

      const response = await axios.post(wishlistAPI, JSON.stringify(postData), { headers });
      const data = response.data;

    } catch (error) {
      showMessage(error, 'error')
    }
  }
}

export const removeWishlistProduct = (wishlistId) => {
  return async (dispatch) => {
    try {
      const postData = {
        type: 'deleteProduct',
        wishlistId: wishlistId
      }

      dispatch(removeProductFromWishlist(wishlistId))
      await axios.post(wishlistAPI, JSON.stringify(postData), { headers })

    } catch (error) {
      showMessage(error, 'error')
    }
  }
}

// Action creators are generated for each case reducer function
export const { addProductToWishlist, removeProductFromWishlist, updateWishlistProducts } = wishlistSlice.actions

export default wishlistSlice.reducer