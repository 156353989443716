import './App.css';
import { BrowserRouter as Router, Routes as Switch, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import { Category } from './components/Category';
import Product from './pages/Product';
import ScrollToTop from './components/ScrollToTop';
import { Wishlist } from './pages/Wishlist';
import { ShoppingCart } from './pages/ShoppingCart';
import { Orders } from './pages/Orders';
import { fetchLoggedInStatus } from './stores/UserSlice';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux'
import { fetchAllProducts } from './stores/ProductSlice';
import { fetchAllCategories, fetchHomePageData } from './stores/CategorySlice';
import { PaymentSuccessfull } from './pages/PaymentSuccessfull';
import ContactUs from './pages/ContactUs';
import Test from './pages/Test';
import { AboutUs } from './pages/AboutUs';
import { PrivacyPolicy } from './pages/PrivacyPolicy';
import { ShippingPolicy } from './pages/ShippingPolicy';
import { TermsAndConditions } from './pages/TermsAndConditions';
import { RefundAndReturnPolicy } from './pages/RefundAndReturnPolicy';
import { TopNavbar } from './components/TopNavbar';
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function App() {
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(fetchLoggedInStatus())
    dispatch(fetchAllProducts(''))
    dispatch(fetchAllCategories())
    dispatch(fetchHomePageData())
  })
  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <TopNavbar />
        <Navbar />
        <Switch>
          <Route exact path='/en-uk/' element={<HomePage />}/>
          <Route exact path='/en-uk/category/:categoryId' element={<Category />}/>
          <Route exact path='/en-uk/product/:productId' element={<Product />} />
          <Route exact path='/en-uk/wishlist' element={<Wishlist />} />
          <Route exact path='/en-uk/shoppingCart' element={<ShoppingCart />} />
          <Route exact path='/en-uk/orders' element={<Orders />} />
          <Route exact path='/en-uk/paymentConfirmation' element={<PaymentSuccessfull />} />
          <Route exact path='/en-uk/contactUs' element={<ContactUs />} />
          <Route exact path='/en-uk/test' element={<Test />} />
          <Route exact path='/en-uk/aboutUs' element={<AboutUs />} />
          <Route exact path='/en-uk/privacyPolicy' element={<PrivacyPolicy />} />
          <Route exact path='/en-uk/shippingPolicy' element={<ShippingPolicy />} />
          <Route exact path='/en-uk/termsAndConditions' element={<TermsAndConditions />} />
          <Route exact path='/en-uk/refundAndReturnPolicy' element={<RefundAndReturnPolicy />} />
        </Switch>
        <Footer />
        <ToastContainer />
      </Router>
    </div>
  );
}

export default App;
