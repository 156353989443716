import React from 'react'
import { Link } from 'react-router-dom'

export const PrivacyPolicy = () => {
    return (
        <div className='px-40 tracking-wider text-start space-y-10 my-36 flex flex-col justify-start items-start'>
            <div className="mx-auto px-4 lg:px-0 mb-12">
                <h1 className="text-3xl font-extrabold font-mono tracking-tight text-gray-900 sm:text-5xl">Privacy Policy</h1>
            </div>
            <div className='space-y-3'>
                <span className='text-2xl'>
                    <strong>Privacy Policy for Gift Realm</strong>
                </span>
                <p><span className='font-bold'>Suggested text:</span> Our website address is: https://giftrealm.co.uk.</p>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'>
                    <strong>1. Introduction</strong>
                </p>
                <p>Welcome to Gift Realm’s Privacy Policy. This policy outlines how we collect, use, and protect your personal information when you use our website and services. By using Gift Realm, you agree to the terms described in this policy.</p>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'>
                    <strong>2. Information We Collect</strong>
                </p>
                <ul className='text-start'>
                    <li className='ml-20 list-disc'><strong>Personal Information:</strong> We may collect personal information, such as your name, email address, shipping address, and payment details when you create an account or make a purchase.</li>
                    <li className='ml-20 list-disc'><strong>Usage Data:</strong> We collect information about how you interact with our website, including pages visited, products viewed, and actions taken.</li>
                    <li className='ml-20 list-disc'><strong>Cookies:</strong> We use cookies and similar tracking technologies to enhance your experience on our website. You can manage your cookie preferences through your browser settings.</li>
                </ul>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'>
                    <strong>3. How We Use Your Information</strong>
                </p>
                <ul className='text-start'>
                    <li className='ml-20 list-disc'><strong>Provide Services:</strong> We use your information to process orders, provide customer support, and deliver products.</li>
                    <li className='ml-20 list-disc'><strong>Communication:</strong> We may send you emails about your orders, promotions, and updates. You can opt-out of marketing emails at any time.</li>
                    <li className='ml-20 list-disc'><strong>Improvement:</strong> Your information helps us understand how to enhance our website and services.
                    </li>
                </ul>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'><strong>4. Data Security</strong></p>
                <p>We take appropriate measures to protect your personal information from unauthorized access, alteration, or disclosure.</p>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'><strong>5. Third-Party Sharing</strong></p>
                <p>We may share your information with trusted third parties, such as payment processors and shipping partners, to fulfill your orders.</p>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'><strong>6. Your Choices</strong></p>
                <p>You can access, update, or delete your personal information by logging into your account. You can also unsubscribe from marketing emails.</p>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'><strong>7. Children’s Privacy</strong></p>
                <p>Our services are not directed at individuals under the age of 13. We do not knowingly collect personal information from children.</p>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'><strong>8. Changes to This Policy</strong></p>
                <p>We may update this policy from time to time. The latest version will be posted on our website.</p>
            </div>
            <div className='space-y-3'>
                <p className='text-2xl'><strong>9. Contact Us</strong></p>
                <p>If you have any questions or concerns about this Privacy Policy, please <Link to="/en-uk/contactUs" className='underline font-bold'>contact us</Link>. </p>

<p>Remember, while this template provides a starting point, it’s important to tailor the privacy policy to the specific data collection and usage practices of your website. If you’re uncertain about legal requirements, it’s advisable to consult with a legal professional to ensure your privacy policy complies with applicable laws and regulations.</p>
            </div>
        </div>
    )
}
