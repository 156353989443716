import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MdOutlineCurrencyPound } from "react-icons/md";
import { useLocation, useNavigate } from "react-router-dom"
import { addProductInWishlist, addProductToWishlist, removeWishlistProduct } from "../stores/WishlistSlice";
import { Rating } from "../components/Rating";
import { Fragment, useState } from 'react'
import { Dialog, Transition, TransitionChild, DialogPanel } from '@headlessui/react'
import { XMarkIcon, HeartIcon, ShoppingBagIcon } from '@heroicons/react/24/outline'
import {  FunnelIcon, HeartIcon as SolidHeartIcon, ShoppingBagIcon as SolidShoppingBagIcon } from '@heroicons/react/20/solid'
import { setCurrentProduct, setSearchedProduct } from '../stores/ProductSlice';
import { fetchCategoryById } from '../stores/CategorySlice';
import { v4 as uuidv4 } from "uuid"
import Slider from "@mui/material/Slider";
import { addProductInShoppingCart, addProductToShoppingCart, removeCartProduct } from '../stores/ShoppingCart';
import { Login } from './Login';

const filters = [
    {
        id: 'sort',
        name: 'Sort',
        type: 'radio',
        options: [
            { label: 'Most Popular', selected: false },
            { label: 'Best Rating', selected: false },
            { label: 'Newest', selected: false },
            { label: 'Price: Low to High', selected: false },
            { label: 'Price: High to Low', selected: false },
            { label: 'Discounted', selected: false },
        ]
    }
]

export function Category() {
    const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)

    const selectedCategory = useSelector((state) => state.category.currentCategory)
    const allProducts = useSelector((state) => state.product.products)
    const searchedProduct = useSelector((state) => state.product.searchedProduct)
    const wishlistProducts = useSelector((state) => state.wishlist.wishlistProducts)
    const shoppingCartProducts = useSelector((state) => state.shoppingCart.shoppingCartProducts)
    const loggedInStatus = useSelector((state) => state.user.loggedInStatus)
    const userDetails = useSelector((state) => state.user.userDetails)
    const homePageData = useSelector((state) => state.category.homePageData)
    const [categoryProducts, setCategoryProducts] = useState(allProducts)
    const [allCategoryProducts, setAllCategoryProducts] = useState(allProducts)
    const [showLoginModal, setShowLoginModal] = useState(false)

    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [range, setRange] = useState([0, 100]);
    function handleChanges(event, newValue) {
        setRange(newValue);
    }

    const handleWishlist = (e, product, type) => {
        if (!loggedInStatus) {
            setShowLoginModal(true);
            return;
        }
        e.stopPropagation();
        if (loggedInStatus) {
            if (type === 'add') {
                dispatch(addProductToWishlist(product))
                dispatch(addProductInWishlist({
                    wishlistId: uuidv4(),
                    userId: userDetails.userId,
                    productId: product.productId
                }))
            }
            else {
                const wishlist = wishlistProducts.find((prod) => prod.productId === product.productId)
                dispatch(removeWishlistProduct(wishlist.wishlistId))
            }
        }
    }

    const handleCart = (e, product, type) => {
        e.stopPropagation();
        if (type === 'add') {
            dispatch(addProductToShoppingCart(product))
            let postData = {
                productId: product.productId
            }
            if (loggedInStatus) {
                postData = {
                    cartId: uuidv4(),
                    userId: userDetails.userId,
                    productId: product.productId,
                    quantity: 1
                }
            }
            dispatch(addProductInShoppingCart(postData, loggedInStatus))
        } else {
            dispatch(removeCartProduct(product, loggedInStatus))
        }
    }

    const navigateToProduct = (product) => {
        dispatch(setCurrentProduct(product))
        navigate(`/en-uk/product/${product.productId}`)
    }

    const handleFilterChange = (filterType, filter) => {
        filters.find((filter) => filter.id === filterType).options.forEach((option) => {
            if (filterType === 'sort') option.checked = false
            if (option.label === filter) {
                option.checked = true
            }
        })
        if (filterType === 'sort') {
            if (filter === 'Most Popular') {
                const productList = homePageData.topProducts ? homePageData.topProducts.split('|') : []
                const mostPopularProducts = allCategoryProducts.filter((product) => productList.includes(product.productId))
                const remainingProducts = allCategoryProducts.filter((product) => !mostPopularProducts.find((popularProduct) => popularProduct.productId === product.productId))
                setCategoryProducts([...mostPopularProducts, ...remainingProducts])
            } else if (filter === 'Best Rating') {
                const filtered = allCategoryProducts.sort((a, b) => parseFloat(b.productReviewRating || "0") - parseFloat(a.productReviewRating || '0'))
                setCategoryProducts([...filtered])
            } else if (filter === 'Newest') {
                const filtered = allCategoryProducts.sort((a, b) => new Date(b.productDate) - new Date(a.productDate))
                setCategoryProducts([...filtered])
            } else if (filter === 'Price: Low to High') {
                const filtered = allCategoryProducts.sort((a, b) => parseFloat(a.productDiscount || a.productPrice) - parseFloat(b.productDiscount || b.productPrice))
                setCategoryProducts([...filtered])
            } else if (filter === 'Price: High to Low') {
                const filtered = allCategoryProducts.sort((a, b) => parseFloat(b.productDiscount || b.productPrice) - parseFloat(a.productDiscount || a.productPrice))
                setCategoryProducts([...filtered])
            } else if (filter === 'Discounted') {
                const filteredCategoryProducts = allCategoryProducts.filter((product) => product.productDiscount)
                const filtered = filteredCategoryProducts.sort((a, b) => parseFloat(b.productDiscount) - parseFloat(a.productDiscount))
                setCategoryProducts([...filtered])
            }
        }
    }

    useEffect(() => {
        if (allProducts.length) {
            const products = []
            const filteredProducts = allProducts.filter((product) => product.productCategoryId === selectedCategory.id)
            filteredProducts.forEach((product) => {
                const price = parseFloat(product.productPrice)
                if (price >= range[0] && price <= range[1]) {
                    products.push(product)
                }
            })
            setCategoryProducts(products)
            setAllCategoryProducts(products)
        }
    }, [range, allProducts])

    useEffect(() => {
        const categoryId = location.pathname.split('/')[3]
        dispatch(fetchCategoryById(categoryId))
    }, [location.pathname, dispatch])

    useEffect(() => {
        setCategoryProducts(allProducts.filter((product) => product.productCategoryId === selectedCategory.id))
        setAllCategoryProducts(allProducts.filter((product) => product.productCategoryId === selectedCategory.id))
        setRange([parseInt(selectedCategory.minPrice) || 0, parseInt(selectedCategory.maxPrice) || 0])
        if (searchedProduct.productId && allProducts.find((product) => product.productCategoryId === selectedCategory.id && product.productId === searchedProduct.productId)) {
            const filterProducts = allProducts.filter((product) => product.productCategoryId === selectedCategory.id && product.productId !== searchedProduct.productId)
            setCategoryProducts([searchedProduct, ...filterProducts])
            setAllCategoryProducts([searchedProduct, ...filterProducts])
        }
    }, [allProducts, selectedCategory])

    useEffect(() => {
        // Return a cleanup function
        return () => {
            dispatch(setSearchedProduct({}))
        };
    }, []);

    return (
        <div className="bg-white">
            <div>
                {showLoginModal && <Login closeModal={() => setShowLoginModal(false)} />}
                {/* Mobile filter dialog */}
                <Transition show={mobileFiltersOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-40 lg:hidden" onClose={setMobileFiltersOpen}>
                        <TransitionChild
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </TransitionChild>

                        <div className="fixed inset-0 z-40 flex">
                            <TransitionChild
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="translate-x-full"
                            >
                                <DialogPanel className="relative ml-auto flex h-full w-full max-w-xs flex-col overflow-y-auto bg-white py-4 pb-12 shadow-xl">
                                    <div className="flex mt-20 items-center justify-between px-4">
                                        <h2 className="text-lg font-medium text-gray-900">Filters</h2>
                                        <button
                                            type="button"
                                            className="-mr-2 flex h-10 w-10 items-center justify-center rounded-md bg-white p-2 text-gray-400"
                                            onClick={() => setMobileFiltersOpen(false)}
                                        >
                                            <span className="sr-only">Close menu</span>
                                            <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                        </button>
                                    </div>

                                    {/* Filters */}
                                    <form className="block lg:hidden px-8 pt-8 border-r h-full">
                                        <h3 className="sr-only">Categories</h3>
                                        <div className='font-medium text-gray-900 text-start w-full'>Price Range</div>

                                        <Slider value={range} onChange={handleChanges} />
                                        <p className='flex items-center text-start break-words border-b border-gray-300 pb-6'>
                                            The selected range is £ {range[0]} - {range[1]}
                                        </p>

                                        {filters.map((section, idx) => (
                                            <div key={idx} className="border-b border-gray-200 py-6">
                                                <div className="font-medium text-gray-900 text-start w-full">{section.name}</div>
                                                <div className="pt-6">
                                                    <div className="space-y-4">
                                                        {section.options.map((option, optionIdx) => (
                                                            <div key={optionIdx} className="flex items-center">
                                                                <input
                                                                    id={`filter-${section.id}-${optionIdx}`}
                                                                    name={`${section.id}[]`}
                                                                    defaultValue={option.value}
                                                                    type={section.type}
                                                                    onChange={() => handleFilterChange(section.id, option.label)}
                                                                    defaultChecked={option.checked}
                                                                    className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                                />
                                                                <label
                                                                    htmlFor={`filter-${section.id}-${optionIdx}`}
                                                                    className="ml-3 text-sm text-gray-600"
                                                                >
                                                                    {option.label}
                                                                </label>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </form>
                                </DialogPanel>
                            </TransitionChild>
                        </div>
                    </Dialog>
                </Transition>

                <main className="mx-auto px-4 sm:px-6 lg:px-12 mt-10">
                    <div className="flex items-baseline justify-between border-b border-gray-200 pb-6 pt-16 sm:pt-24 lg:pt-36">
                        <h1 className="text-4xl font-bold tracking-tight text-start text-gray-900">{selectedCategory && selectedCategory.name}</h1>

                        <div className="flex items-center">
                            <button
                                type="button"
                                className="-m-2 ml-4 p-2 text-gray-400 hover:text-gray-500 sm:ml-6 lg:hidden"
                                onClick={() => setMobileFiltersOpen(true)}
                            >
                                <span className="sr-only">Filters</span>
                                <FunnelIcon className="h-5 w-5" aria-hidden="true" />
                            </button>
                        </div>
                    </div>

                    <section aria-labelledby="products-heading" className="pb-24 pt-6 ">

                        <div className="grid grid-cols-1 gap-x-8 gap-y-10 lg:grid-cols-4 ">
                            {/* Filters */}
                            <form className="hidden lg:block pt-3 px-2 2xl:px-8 border-r h-full">
                                <h3 className="sr-only">Categories</h3>
                                <div className='font-medium text-gray-900 text-start w-full'>Price Range</div>

                                <Slider value={range} onChange={handleChanges} max={range[1] || 100} />
                                <p className='flex items-center text-start break-words border-b border-gray-300 pb-6'>
                                    The selected range is £ {range[0]} - {range[1]}
                                </p>

                                {filters.map((section, idx) => (
                                    <div key={idx} className="border-b border-gray-200 py-6">
                                        <div className="font-medium text-gray-900 text-start w-full">{section.name}</div>
                                        <div className="pt-6">
                                            <div className="space-y-4">
                                                {section.options.map((option, optionIdx) => (
                                                    <div key={optionIdx} className="flex items-center">
                                                        <input
                                                            id={`filter-${section.id}-${optionIdx}`}
                                                            name={`${section.id}[]`}
                                                            defaultValue={option.value}
                                                            type={section.type}
                                                            onChange={() => handleFilterChange(section.id, option.label)}
                                                            defaultChecked={option.checked}
                                                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                                                        />
                                                        <label
                                                            htmlFor={`filter-${section.id}-${optionIdx}`}
                                                            className="ml-3 text-sm text-gray-600"
                                                        >
                                                            {option.label}
                                                        </label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </form>

                            {/* Product grid */}
                            <div className='lg:col-span-3'>
                                {categoryProducts.length > 0 ? <div className="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-2 2xl:grid-cols-3 xl:gap-x-8 2xl:mx-6">
                                    {
                                        categoryProducts.length > 0 && categoryProducts.map((bestProduct) => {
                                            return (
                                                <div key={bestProduct.productId} className="group border rounded-lg shadow-xl cursor-pointer p-3 py-6" onClick={() => navigateToProduct(bestProduct)}>
                                                    <div className="relative aspect-h-1 z-0 aspect-w-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-h-8 xl:aspect-w-7">
                                                        <img
                                                            src={bestProduct.productImages[0].imageSrc}
                                                            alt={bestProduct.productImages[0].imageAlt}
                                                            className="h-80 w-full object-fill object-center group-hover:opacity-75"
                                                        />
                                                        <div className="absolute z-10 top-5 space-y-3 right-5 bg-white px-2 py-3 hidden group-hover:block">
                                                            {
                                                                wishlistProducts.find((product) => product.productId === bestProduct.productId) ?
                                                                    <SolidHeartIcon className="w-7 h-7 hover:scale-110 text-red-600" onClick={(event) => handleWishlist(event, bestProduct, 'remove')} /> :
                                                                    <HeartIcon className="w-7 h-7 hover:scale-110" onClick={(event) => handleWishlist(event, bestProduct, 'add')} />
                                                            }
                                                            {
                                                                shoppingCartProducts.find((product) => product.productId === bestProduct.productId) ?
                                                                    <SolidShoppingBagIcon className='w-7 h-7 hover:scale-110' onClick={(event) => handleCart(event, bestProduct, 'remove')} /> :
                                                                    <ShoppingBagIcon className="w-7 h-7 hover:scale-110" onClick={(event) => handleCart(event, bestProduct, 'add')} />
                                                            }
                                                        </div>
                                                    </div>
                                                    <h3 className="mt-8 text-md w-fit text-gray-700">{bestProduct.productName}</h3>
                                                    <div className="grid grid-cols-2 mt-2">
                                                        <div className="flex text-start items-center">
                                                            <MdOutlineCurrencyPound />
                                                            <p className={bestProduct.productDiscount && "line-through"}>{bestProduct.productPrice}</p>
                                                            <span className="ml-2">{bestProduct.productDiscount}</span>
                                                        </div>
                                                        <div className="flex justify-end items-center">
                                                            <Rating rating={bestProduct.productReviewRating} />
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div> : <div className='flex text-3xl font-semibold items-center justify-center w-full h-full mx-auto'>
                                            No Product Found
                                        </div>}
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        </div>
    )
}
