import React, { useEffect, useState } from 'react'
import { CheckIcon, XMarkIcon, QuestionMarkCircleIcon, ArrowLeftIcon } from '@heroicons/react/24/solid'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { MdOutlineCurrencyPound } from "react-icons/md";
import PaymentPage from '../components/PaymentPage';
import { addProductInShoppingCart, addProductToShoppingCart, addSaveForLater, removeCartProduct, removeSaveForLater, updateCartProduct, updateShoppingCartProducts } from '../stores/ShoppingCart';
import {v4 as uuidv4} from "uuid"
import Modal from '../components/Modal';
import { AddressDetails } from '../components/AddressDetails';
import { fetchShippingInfo, showMessage, shippingCharges, calculateShippingInfo } from '../helper';
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { setCurrentProduct } from '../stores/ProductSlice';
import { Login } from '../components/Login';


export const ShoppingCart = () => {

    const shoppingCartProducts = useSelector((state) => state.shoppingCart.shoppingCartProducts)
    const saveForLaterProducts = useSelector((state) => state.shoppingCart.saveForLater)
    const allProducts = useSelector((state) => state.product.products)
    const isUserLoggedIn = useSelector((state) => state.user.loggedInStatus)
    const userDetails = useSelector((state) => state.user.userDetails)
    const [products, setProducts] = useState([])
    const [laterProducts, setLaterProducts] = useState(saveForLaterProducts)
    const [totalBill, setTotalBill] = useState({subtotal: '0', shippingEstimate: '0', totalDiscount: '0'})
    const [showPaymentForm, setPaymentForm] = useState(false)
    const [showDialog, setShowDialog] = useState(false)
    const [showAddressForm, setShowAddressForm] = useState(false)
    const [relatedProducts, setRelatedProducts] = useState([])
    const [showLoginModal, setShowLoginModal] = useState(false)
    const navigate = useNavigate()
    const selectedAddress = useSelector((state) => state.shoppingCart.selectedAddress)

    const validateAddress = () => {
        const validatedAddress = { ...selectedAddress }
        if (validatedAddress.fullName === '') {showMessage('Please fill your Full Name', 'error'); return null}
        if (validatedAddress.phoneNumber === '') {showMessage('Please fill your Phone Number', 'error'); return null}
        // if (validatedAddress.phoneNumber !== '' && validatedAddress.phoneNumber.length !== 10) {showMessage('Your phone number should be of length 10', 'error'); return null}
        if (validatedAddress.firstAddressLine === '') {showMessage('Please fill Address Details', 'error'); return null}
        if (validatedAddress.pincode === '') {showMessage('Please mention your pincode', 'error'); return null}

        if (validatedAddress.addressId === 'new') validatedAddress.addressId = uuidv4()
        return validatedAddress
    }

    const handleCheckout = () => {
        if (products.some((product) => !parseInt(product.productStock) > 0)) {
            setShowDialog(true)
            return;
        }
        if (isUserLoggedIn) {
            setShowAddressForm(true)
        } else {
            setShowLoginModal(true)
        }
    }

    const payNow = () => {
        const validatedAddress = validateAddress()
        if (validatedAddress) {
            setShowAddressForm(false)
            setPaymentForm(true)
        }
    }
    const dispatch = useDispatch()

    const saveForLater = (product) => {
        if (!isUserLoggedIn) {
            setShowLoginModal(true)
            return
        }
        const filterCart = shoppingCartProducts.filter((cart) => cart.productId === product.productId)[0]
        const data = {
            id: filterCart.cartId,
            productId: product.productId,
            userId: userDetails.userId
        }
        dispatch(addSaveForLater(data))
        dispatch(removeCartProduct(filterCart, isUserLoggedIn))
    }

    const addToSaveForLater = () => {
        const filteredProducts = products.filter((prod) => !parseInt(prod.productStock) > 0)
        filteredProducts.map((product) => saveForLater(product))
        setShowDialog(false)
        setShowAddressForm(true)
    }

    const closeModal = () => {
        setShowDialog(false)
    }

    const calculateTotalBill = (filteredProducts) => {
        let discountPrice = 0
        let price = 0
        let productWeight = 0
        const info = {subtotal: 0, shippingEstimate: 0, totalDiscount: 0}
        filteredProducts.forEach((product) => {
            if (parseInt(product.productStock) > 0) {
                price += (parseFloat(product.productPrice) * parseInt(product.quantity))
                discountPrice += (parseFloat(product.productDiscount) * parseInt(product.quantity))
                productWeight += (parseFloat(product.productWeight) * parseInt(product.quantity))
            }
        })
        info.totalDiscount = (parseFloat(price) - parseFloat(discountPrice)).toFixed(2)
        info.subtotal = parseFloat(price).toFixed(2)
        info.shippingEstimate = calculateShippingInfo(productWeight)
        setTotalBill(info)
    }

    const removeSaveForLaterProducts = (product) => {
        const filterCart = saveForLaterProducts.filter((cart) => cart.productId === product.productId)
        dispatch(removeSaveForLater(filterCart[0].id, isUserLoggedIn))
    }

    const removeProduct = (product) => {
        const filterCart = shoppingCartProducts.filter((cart) => cart.productId === product.productId)
        dispatch(removeCartProduct(filterCart[0], isUserLoggedIn))
    }

    const backNavigation = () => {
        if (showAddressForm) setShowAddressForm(false)
        if (showPaymentForm) {setPaymentForm(false);setShowAddressForm(true)}
    }

    const addToCart = (product) => {
        const filteredProduct = saveForLaterProducts.find((prod) => prod.productId === product.productId)
        const postData = {
            cartId: filteredProduct.id,
            userId: filteredProduct.userId,
            productId: product.productId,
            quantity: 1
        }
        dispatch(removeSaveForLater(filteredProduct.id))
        dispatch(addProductInShoppingCart(postData, isUserLoggedIn))
    }

    const addInCart = (product) => {
        dispatch(addProductToShoppingCart(product))
        let postData = {
            productId: product.productId
        }
        if (isUserLoggedIn) {
            postData = {
                cartId: uuidv4(),
                userId: userDetails.userId,
                productId: product.productId,
                quantity: 1
            }
        }
        dispatch(addProductInShoppingCart(postData, isUserLoggedIn))
    }

    const changeQuantity = (type, id) => {
        let changedQuantity = []
        if (type === '+') {
            changedQuantity = products.map((product) => {
                if (product.productId === id) {
                    product.quantity = parseInt(product.quantity) + 1
                }
                return product
            })
        } else {
            changedQuantity = products.map((product) => {
                if (product.productId === id) {
                    product.quantity = parseInt(product.quantity) - 1
                }
                return product
            })
        }
        calculateTotalBill(changedQuantity)
        const shoppingProducts = shoppingCartProducts.map((prod) => {
            let quant = prod.quantity
            if(prod.productId === id) {
                if (type === '+') quant = `${parseInt(prod.quantity) + 1}`
                else quant = `${parseInt(prod.quantity) - 1}`
            }
            return {
                ...prod,
                quantity: quant
            }
        })
        const product = shoppingProducts.find((prod) => prod.productId === id)
        dispatch(updateShoppingCartProducts(shoppingProducts))
        dispatch(updateCartProduct(product, isUserLoggedIn))
        setProducts(changedQuantity)
    }
    
    // Use Effect for calculating total bill amount
    useEffect(() => {
        const cartIds = shoppingCartProducts.map((product) => product.productId)
        let filteredProducts = allProducts.filter((product) => cartIds.includes(product.productId))
        filteredProducts = filteredProducts.map((product) => {
            const quantity = shoppingCartProducts.find((prod) => prod.productId === product.productId).quantity
            return ({
                ...product,
                quantity: quantity || "1"
            })
        })
        setProducts(filteredProducts)
        calculateTotalBill(filteredProducts)
    }, [shoppingCartProducts, allProducts])

    // Use Effect For filtering save for later products
    useEffect(() => {
        const cartIds = saveForLaterProducts.map((product) => product.productId)
        const filteredProducts = allProducts.filter((product) => cartIds.includes(product.productId))
        setLaterProducts(filteredProducts)
    }, [saveForLaterProducts, allProducts])

    // For making related products
    useEffect(() => {
        const productIds = shoppingCartProducts.map((product) => product.productId)
        const saveForLaterIds = saveForLaterProducts.map((product) => product.productId)
        const categoryIds = []
        allProducts.forEach((product) => {
            if (productIds.includes(product.productId)) {
                categoryIds.push(product.productCategoryId)
            }
        })
        const selectedItems = [];
        for (let j = 0; j<categoryIds.length; j++) {
            const array = allProducts.filter((product) => product.productCategoryId === categoryIds[j])
            const arrayCopy = array.slice(); // Make a copy of the original array
            for (let i = 0; i < 3; i++) {
                const randomIndex = Math.floor(Math.random() * arrayCopy.length);
                const selectedItem = arrayCopy.splice(randomIndex, 1)[0]; // Remove and get the selected item
                if (selectedItem) selectedItems.push(selectedItem);
            }
        }
        const selectedProducts = new Set(selectedItems)
        const abc = []
        selectedProducts.forEach((product) => {if (!productIds.includes(product.productId) && !saveForLaterIds.includes(product.productId)) abc.push(product)})
        if (selectedItems[0]) setRelatedProducts(abc.slice(0, 4))
    }, [shoppingCartProducts, allProducts])

    return (
        <div className="bg-white py-16 sm:py-24 mx-4 xs:mx-16 2xl:mx-32">
            {showLoginModal && <Login closeModal={() => setShowLoginModal(false)}/>}
            <main className="pt-16 pb-24">
                <div className="mx-auto sm:px-2 lg:px-8">
                    <div className="mx-auto ">
                        <h1 className="text-3xl max-lg:text-start font-extrabold font-mono tracking-tight text-gray-900 sm:text-5xl">Shopping Cart</h1>
                    </div>
                    {
                        products.length === 0 && <div className='py-32 flex flex-col space-y-5 items-center justify-center'>
                            <h2 className='text-xl font-semibold'>Your Cart is Empty!!!</h2>
                            <button className='text-white bg-black p-5 w-fit px-10 hover:bg-[#b26b53] transition-all ease-in-out duration-500' onClick={() => navigate('/en-uk')}>Go back to Home Page</button>
                        </div>
                    }
                </div>
                {
                    (showAddressForm || showPaymentForm) && <div className='flex items-center mt-12 gap-2 cursor-pointer' onClick={() => backNavigation()}>
                        <ArrowLeftIcon className='w-5 h-5'/>
                        Back
                    </div>
                }
                {showDialog && <Modal title="Some of the products are out of stock" subTitle="You can add them as save for later, we will notify once they are back in stock" mainBtnText="Move To Save For Later" mainBtnAction={addToSaveForLater} secondBtnText="Cancel" secondBtnAction={closeModal}/>}
                <div className="mt-5 lg:grid lg:grid-cols-12 lg:gap-x-12 lg:items-start xl:gap-x-16">
                    <section aria-labelledby="cart-heading" className={showPaymentForm ? "lg:col-span-0" : "lg:col-span-8"}>


                        <ul className={[products.length && !showPaymentForm > 0 && 'border-t border-b border-gray-200',  'divide-y divide-gray-200']}>
                            {
                                !showAddressForm && !showPaymentForm && products.map((product, productIdx) => {
                                    return (

                                        <li key={product.productId} className="flex py-6 sm:py-10">
                                            <div className="flex-shrink-0">
                                                <img 
                                                    src={product.productImages && product.productImages.length > 0 && product.productImages[0].imageSrc} 
                                                    alt={product.productImages && product.productImages.length > 0 && product.productImages[0].imageAlt} 
                                                    className="w-24 h-24 cursor-pointer hover:opacity-80 rounded-md object-center object-cover xs:w-32 sm:w-48 xs:h-32 sm:h-48" 
                                                    onClick={() => {dispatch(setCurrentProduct(product)); navigate(`/en-uk/product/${product.productId}`)}}
                                                />
                                            </div>

                                            <div className="ml-4 flex-1 flex flex-col justify-between sm:ml-6">
                                                <div className="relative sm:grid sm:grid-cols-12 sm:gap-x-6 sm:pr-0">
                                                    <div className='col-span-7 flex flex-col'>
                                                        <div className="flex justify-start text-start">
                                                            <h3 className="text-sm">
                                                                <div className="font-medium text-xl text-gray-700 hover:text-gray-800 cursor-pointer hover:underline" onClick={() => {dispatch(setCurrentProduct(product)); navigate(`/en-uk/product/${product.productId}`)}}>
                                                                    {product.productName}
                                                                </div>
                                                                <div className='text-gray-500 text-base'>
                                                                    {product.productShortDescription}
                                                                </div>
                                                            </h3>
                                                        </div>
                                                        <div className="mt-1 w-full flex text-sm items-center justify-start text-start">
                                                            <MdOutlineCurrencyPound className="w-6 h-6 text-gray-500" />
                                                            <div className="flex text-gray-500 text-lg">
                                                                <p className={product.productDiscount && "line-through"}>{(parseFloat(product.productPrice) * parseInt(product.quantity)).toFixed(2)}</p>
                                                                <span className="ml-2">{(parseFloat(product.productDiscount) * parseInt(product.quantity)).toFixed(2)}</span>
                                                            </div>
                                                        </div>
                                                        <div className='flex items-center justify-start'>
                                                            <p className="text-lg text-gray-500">
                                                                {`${product.productSize} ${product.productSizeType}`}
                                                            </p>
                                                        </div>
                                                    </div>

                                                    <div className="col-span-5 flex mt-4 pb-4 sm:justify-end space-x-5 h-fit w-full sm:mt-0 ">
                                                        <button disabled={product.quantity <= 1} className={product.quantity <= 1 ? 'opacity-40 cursor-not-allowed border w-6 h-6 text-lg flex items-center justify-center border-gray-500' :'border w-6 h-6 text-lg flex items-center justify-center border-gray-500'} onClick={() => changeQuantity('-', product.productId)}>-</button>
                                                        <p>{product.quantity || "1"}</p>
                                                        <button disabled={parseInt(product.quantity) >= parseInt(product.productStock)} className={parseInt(product.quantity) >= parseInt(product.productStock) ? 'opacity-40 cursor-not-allowed border w-6 h-6 text-lg flex items-center justify-center border-gray-500' : 'border w-6 h-6 text-lg flex items-center justify-center border-gray-500'} onClick={() => changeQuantity('+', product.productId)}>+</button>
                                                    </div>
                                                </div>

                                                <div className='flex flex-col sm:flex-row sm:items-center sm:justify-between'>
                                                    <p className="flex text-sm text-gray-700 space-x-2">
                                                        {
                                                            product.productStock > 0 ? <CheckIcon className="flex-shrink-0 h-5 w-5 text-green-500" aria-hidden="true" /> :
                                                            <XMarkIcon className="flex-shrink-0 h-5 w-5 text-red-500" aria-hidden="true" />
                                                        }
                                                        
                                                        <span>{product.productStock > 0 ? 'In stock' : 'Out of Stock'}</span>
                                                    </p>
                                                    <div className='flex items-center space-x-3'>
                                                        <p className='text-sm underline text-blue-500 hover:text-blue-800 cursor-pointer' onClick={() => saveForLater(product)}>Save For Later</p>
                                                        <button className='flex w-fit text-white bg-red-600 hover:bg-red-500 rounded-lg py-2 px-3' onClick={() => removeProduct(product)}>
                                                            Remove
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    )

                                }) }
                                {showAddressForm && <AddressDetails />}
                        </ul >
                    </section >

                    {/* // < !--Order summary-- > */}
                        {
                            products.length > 0 && <div className={[showPaymentForm ? 'lg:col-span-full w-full flex-col flex lg:flex-row justify-between':'lg:col-span-4']}>
                                <section aria-labelledby="summary-heading" className={[showPaymentForm && 'w-full lg:max-w-[35%] text-start', "w-full mt-16 bg-gray-50 rounded-lg py-6 sm:py-6 lg:py-8 lg:mt-8"]}>
                                    <h2 id="summary-heading" className="text-start -mt-10 mb-12 text-2xl font-medium text-gray-900">Order Summary</h2>

                                    <dl className="mt-6 space-y-4">
                                        <div className="flex items-center justify-between">
                                            <dt className="text-sm text-gray-600">
                                                Subtotal
                                            </dt>
                                            <dd className="flex text-base font-medium text-gray-900">
                                                {totalBill && totalBill.subtotal}
                                            </dd>
                                        </div>
                                        <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                                            <dt className="text-sm text-gray-600">
                                                Discount
                                            </dt>
                                            <dd className="text-base flex font-medium text-gray-900">
                                                {totalBill && totalBill.totalDiscount}
                                            </dd>
                                        </div>
                                        <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                                            <dt className="flex items-center text-sm text-gray-600">
                                                <span>Shipping estimate</span>
                                                <Link href="#" className="ml-2 flex-shrink-0 text-gray-400 hover:text-gray-500">
                                                    <QuestionMarkCircleIcon className="h-5 w-5" aria-hidden="true" />
                                                </Link>
                                            </dt>
                                            <dd className="text-base flex font-medium text-gray-900">
                                                {totalBill && totalBill.shippingEstimate}
                                            </dd>
                                        </div>
                                        <div className="border-t border-gray-200 pt-4 flex items-center justify-between">
                                            <dt className="text-base font-medium text-gray-900">
                                                Order total
                                            </dt>
                                            <dd className="flex text-base font-medium text-gray-900">
                                                <MdOutlineCurrencyPound className="w-6 h-6 text-gray-900" />
                                                {totalBill && (parseFloat(totalBill.subtotal) - parseFloat(totalBill.totalDiscount) + parseFloat(totalBill.shippingEstimate)).toFixed(2)}
                                            </dd>
                                        </div>
                                    </dl>

                                    <div className="mt-6">
                                        {showAddressForm ? <button type="button" className="w-full bg-indigo-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500" onClick={() => payNow()}>
                                            Select Payment Option
                                        </button> :
                                        !showPaymentForm && <button type="button" className="w-full bg-indigo-600 border border-transparent rounded-md shadow-sm py-3 px-4 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-indigo-500" onClick={() => handleCheckout()}>
                                            Checkout
                                        </button>
                                        }
                                    </div>
                                </section>
                                <div className='w-full lg:justify-end flex'>
                                    {showPaymentForm && <PaymentPage paymentAmount={(parseFloat(totalBill.subtotal) - parseFloat(totalBill.totalDiscount) + parseFloat(totalBill.shippingEstimate)).toFixed(2).toString()}/>}
                                </div>
                            </div>
                        }
                    </div>

                {/* //   < !--Save for products-- > */}
                {!showPaymentForm && isUserLoggedIn && laterProducts.length > 0 && <section aria-labelledby="related-heading" className="mt-24">
                    <h2 id="related-heading" className="text-2xl text-start font-medium text-gray-900">Save For Later</h2>

                    <div className="my-12 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">

                        {
                            laterProducts.map((relatedProduct, idx) => {
                                return (
                                    <div key={relatedProduct.productId} className="group relative">
                                        <div className="w-full flex justify-center aspect-w-1 aspect-h-1 rounded-md overflow-hidden object-cover group-hover:opacity-75 lg:aspect-none">
                                            <img src={relatedProduct.productImages && relatedProduct.productImages.length > 0  && relatedProduct.productImages[0].imageSrc} alt="relatedProduct.imageAlt" className="w-full h-full object-center object-cover lg:w-48 lg:h-48" />
                                        </div>
                                        <div className="mt-4 flex justify-between text-start group" onClick={() => {dispatch(setCurrentProduct(relatedProduct)); navigate(`/en-uk/product/${relatedProduct.productId}`)}}>
                                            <div>
                                                <h3 className="text-base group-hover:underline text-gray-700">
                                                    <Link href="relatedProduct.href">
                                                        <span aria-hidden="true" className="absolute inset-0" />
                                                        {relatedProduct.productName}
                                                    </Link>
                                                </h3>
                                                <div className='flex mt-2'>
                                                    <MdOutlineCurrencyPound className="w-6 h-6 text-gray-500" />
                                                    <div className="flex text-gray-500 text-lg">
                                                        <p className={relatedProduct.productDiscount && "line-through"}>{relatedProduct.productPrice}</p>
                                                        <span className="ml-2">{relatedProduct.productDiscount}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="text-base font-medium text-gray-900">{relatedProduct.price}</p>
                                        </div>
                                        <div className='absolute flex w-full justify-center space-x-5 -bottom-16'>
                                            <button className='flex border w-full justify-center py-2.5 bg-red-500 border-red-500 hover:border-red-600 rounded-lg hover:bg-red-600 text-white ease-in-out duration-500' onClick={() => removeSaveForLaterProducts(relatedProduct)}>Remove</button>
                                            <button className='flex border w-full justify-center py-2.5 border-gray-400 hover:border-[#b26b53] rounded-lg hover:bg-[#b26b53] hover:text-white ease-in-out duration-500' onClick={() => addToCart(relatedProduct)}>Add To Cart</button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div >
                </section >}

                {/* //   < !--Related products-- > */}
                {!showPaymentForm && relatedProducts.length > 0 && <section aria-labelledby="related-heading" className="mt-40">
                    <h2 id="related-heading" className="text-2xl font-medium text-gray-900 text-start">You may also like&hellip;</h2>
                    
                    <div className="mt-6 grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8 xl:gap-y-20">

                    {
                            relatedProducts.length > 0 && relatedProducts.map((relatedProduct, idx) => {
                                return (
                                    <div key={relatedProduct.productId} className="group relative max-sm:mt-20">
                                        <div className="w-full flex justify-center aspect-w-1 aspect-h-1 rounded-md overflow-hidden object-cover group-hover:opacity-75 lg:aspect-none">
                                            <img src={relatedProduct.productImages && relatedProduct.productImages.length > 0  && relatedProduct.productImages[0].imageSrc} alt="relatedProduct.imageAlt" className="w-full h-full object-center object-cover lg:w-48 lg:h-48" />
                                        </div>
                                        <div className="mt-4 flex justify-between group text-start" onClick={() => {dispatch(setCurrentProduct(relatedProduct)); navigate(`/en-uk/product/${relatedProduct.productId}`)}}>
                                            <div>
                                                <h3 className="text-base group-hover:underline text-gray-700">
                                                    <Link href="relatedProduct.href">
                                                        <span aria-hidden="true" className="absolute inset-0" />
                                                        {relatedProduct.productName}
                                                    </Link>
                                                </h3>
                                                <div className='flex mt-2'>
                                                    <MdOutlineCurrencyPound className="w-6 h-6 text-gray-500" />
                                                    <div className="flex text-gray-500 text-lg">
                                                        <p className={relatedProduct.productDiscount && "line-through"}>{relatedProduct.productPrice}</p>
                                                        <span className="ml-2">{relatedProduct.productDiscount}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="text-base font-medium text-gray-900">{relatedProduct.price}</p>
                                        </div>
                                        <div className='absolute flex w-full justify-center -bottom-16'>
                                            <button className='flex border w-full justify-center py-2.5 border-gray-400 hover:border-[#b26b53] rounded-lg hover:bg-[#b26b53] hover:text-white ease-in-out duration-500' onClick={() => addInCart(relatedProduct)}>Add To Cart</button>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div >
                </section >}
            </main >
            <ToastContainer />
        </div >
    )
}
