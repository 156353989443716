import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition, DialogTitle, TransitionChild, DialogPanel } from '@headlessui/react'
import { ExclamationTriangleIcon } from '@heroicons/react/24/outline'
import { StarIcon } from "@heroicons/react/24/solid"
import { reviewsAPI, headers, productAPI } from '../APIRoutes'
import axios from "axios"
import { v4 as uuidv4 } from "uuid"
import { useDispatch, useSelector } from 'react-redux'
import { LuLoader2 } from 'react-icons/lu';
import { fetchProductById, setCurrentProduct } from '../stores/ProductSlice'

export default function ReviewModal({ closeModal, productId, currentRating, handleSubmit }) {
  const [open, setOpen] = useState(true)
  const [loading, setLoading] = useState(false)
  const [review, setReview] = useState('')
  const [rating, setRating] = useState(0)
  const [subRating, setSubRating] = useState(0)
  const userDetails = useSelector((state) => state.user.userDetails)
  const currentProduct = useSelector((state) => state.product.currentProduct)
  const dispatch = useDispatch()

  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }

  const updateProductRating = async () => {
    const rate = (parseInt(parseInt(currentProduct.productReviewRating || "0") + rating))
    const postData = {
      ...currentProduct,
      type: 'updateProduct',
      productReviewRating: `${currentProduct.productReviewRating ? parseInt(rate / 2) : rate}`
    }
    axios.post(productAPI, JSON.stringify(postData), { headers }).then(async(resp) => {
      dispatch(fetchProductById(productId)).then((res) => {
        setLoading(false);
        closeModal(false);
      })
    })
  }

  const addReview = () => {
    if (review !== '') {
      setLoading(true)
      const postData = {
        type: 'addReview',
        reviewId: uuidv4(),
        userId: userDetails.userId,
        reviewerName: userDetails.userName,
        productId: productId,
        rate: rating,
        review: review
      }
      const data = axios.post(reviewsAPI, JSON.stringify(postData), {headers})
      data.then((response) => { if (response.data.data === 'Success') { updateProductRating(); handleSubmit(); }})
    }
  }

  const cancelButtonRef = useRef(null)

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={() => { setOpen(); closeModal() }}>
        <TransitionChild
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </TransitionChild>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full justify-center text-center items-center p-0">
            <TransitionChild
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-0 scale-95"
              enterTo="opacity-100 translate-y-0 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 scale-100"
              leaveTo="opacity-0 translate-y-0 scale-95"
            >
              <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                  <div className="flex w-[100%]">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-blue-100 sm:mx-0 sm:h-10 sm:w-10">
                      <ExclamationTriangleIcon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                    </div>
                    <div className="ml-4 text-left">
                      <DialogTitle as="h3" className="text-base font-semibold leading-6 text-gray-900">
                        Write a Review
                      </DialogTitle>
                      <div>
                        <p className='text-sm'>
                          What are your thoughts on this product?
                        </p>
                      </div>
                      <div className='flex justify-start items-center space-x-5 my-5'>
                        <p>Rate the product: </p>
                        <div className='flex'>
                          {
                            [0, 1, 2, 3, 4].map((rate) => {
                              return (
                                <StarIcon key={rate} className={classNames((subRating > 0 && subRating > rate) || rating > rate ? 'text-yellow-400' : 'text-gray-300', 'flex-shrink-0 h-6 w-6 cursor-pointer')} aria-hidden="true" onMouseOver={() => setSubRating(rate+1)} onMouseLeave={() => setSubRating(0)} onClick={() => setRating(rate+1)}/>
                              )
                            })
                          }
                        </div>
                      </div>
                      <label htmlFor="caption">Write About the Product:</label>
                      <div className='flex'>
                        <input id='caption' type="text" name='imageAlt' value={review} placeholder='Enter here...' className='bg-transparent w-96 border-2 border-gray-400 rounded-lg p-2'
                          onChange={(e) => setReview(e.target.value)} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 flex flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex justify-center items-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 ml-3 w-auto"
                    onClick={() => { addReview() }}
                  >
                    Submit Review
                    {loading && <LuLoader2 className='animate-spin w-6 h-6 ml-3'/>}
                  </button>
                  <button
                    type="button"
                    className="inline-flex justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 mt-0 w-auto"
                    onClick={() => closeModal(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}