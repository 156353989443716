import React from 'react';
import { Fragment, useEffect, useState } from 'react'
import { Disclosure, Menu, Transition, MenuItem, MenuItems, MenuButton, DisclosureButton, DisclosurePanel } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'
import { Link, useLocation } from 'react-router-dom';
import { MagnifyingGlassIcon, HeartIcon, ShoppingBagIcon, UserIcon } from '@heroicons/react/24/outline'
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux'
import { getAddress, updateLoggedInStatus } from '../stores/UserSlice';
import { fetchWishlistProducts } from '../stores/WishlistSlice';
import { fetchSaveForLater, fetchShoppingCartProducts } from '../stores/ShoppingCart';
import { fetchOrders } from '../stores/OrderSlice';
import { CategoryDropdown } from './CategoryDropdown';
import { Login } from './Login';
import { setSearchedProduct } from '../stores/ProductSlice';
import { setCurrentCategory } from '../stores/CategorySlice';


export default function Navbar() {
  const location = useLocation();
  const navigate = useNavigate();

  const homePageData = useSelector((state) => state.category.homePageData)
  const wishlistProducts = useSelector((state) => state.wishlist.wishlistProducts)
  const shoppingCartProducts = useSelector((state) => state.shoppingCart.shoppingCartProducts)
  const isUserLoggedIn = useSelector((state) => state.user.loggedInStatus)
  const allCategories = useSelector((state) => state.category.categories)
  const allProducts = useSelector((state) => state.product.products)
  const userDetails = useSelector((state) => state.user.userDetails)
  const [showDropdown, setShowDropdown] = useState(false)
  const [searchDropdown, setSearchDropdown] = useState(false)
  // const [selectedSpecialCategory, setSpecialCategory] = useState({})
  const dispatch = useDispatch()

  const [query, setQuery] = useState('')

  const [navigation, setNavigation] = useState([

    { name: 'All Categories', href: '#', hidden: false, current: false, type: 'normal' },
    { name: 'Gifts & Hampers', href: '/en-uk/category/dfdc9523-d3d0-4576-8938-d2221bade58c', hidden: false, current: false, type: 'normal' },
    { name: 'Collectibles', href: '/en-uk/category/8b6fea5d-5103-43e5-ba90-21df025b3e46', hidden: false, current: false, type: 'normal' },
    { name: 'Men', href: '/en-uk/category/dc178979-f8d3-4b80-9809-e28004e8fd8c', hidden: true, current: false, type: 'normal' },
    { name: 'Women', href: '/en-uk/category/d9ab262f-0d91-4570-8996-7aea58adf4ae', hidden: true, current: false, type: 'normal' },
    { name: 'Special Category', href: `/en-uk/category/${homePageData.specialCategory}`, hidden: true, current: false, type: 'special' },
  ])

  const mobileNavigations = allCategories.map((category) => {return { name: category.name, href: `/en-uk/category/${category.id}` } })

  const [filteredSearchList, setFilteredSearchList] = useState([])
  const [showLoginModal, setShowLoginModal] = useState(false)

  const handleMouseOver = (index) => {
    if (index === 0) {
      setShowDropdown(true)
    }
  }

  const handleMouseLeft = (index) => {
    if (index === 0) {
      setShowDropdown(false)
    }
  }

  const searchQuery = (event) => {
    setSearchDropdown(true)
    const searchedResult = event.target.value
    setQuery(searchedResult)
    if (searchedResult === '') {
      setFilteredSearchList([])
      setShowDropdown(false)
      return;
    }

    const filtered = allProducts.filter((product) => product.productName.toLowerCase().indexOf(searchedResult.toLowerCase()) !== -1)
    if (filtered.length) {
      setFilteredSearchList(filtered.map((filter) => { return { id: filter.productId, name: filter.productName } }))
    }
    else setFilteredSearchList([{ id: 'notfound', name: 'Not Found' }])
  }

  const handleLogin = () => {
    if (isUserLoggedIn) {
      localStorage.removeItem('user')
      localStorage.setItem('loggedInStatus', false)
      dispatch(updateLoggedInStatus(false))
      return;
    }
    setShowLoginModal(true)
  }

  const isItemSelected = () => {
    setShowDropdown(false)
  }

  const navigateToCategoryPage = (product) => {
    const filter = allProducts.find((prod) => prod.productId === product.id)
    const currentCategory = allCategories.find((category) => category.id === filter.productCategoryId)
    dispatch(setSearchedProduct(filter))
    dispatch(setCurrentCategory(currentCategory))
    setSearchDropdown(false)
    navigate(`/en-uk/category/${currentCategory.id}`)
    // dispatch(setProducts(filteredProducts))
  }

  useEffect(() => {
    const setNavigationFalse = navigation.map((nav) => { if (nav.current === true) { nav.current = false } return nav })
    const updateNavigationList = setNavigationFalse.map((nav) => { if (nav.href === location.pathname.toString()) { nav.current = true } return nav })
    setNavigation(updateNavigationList)
  }, [location])

  useEffect(() => {
    if (isUserLoggedIn) {
      dispatch(fetchWishlistProducts(userDetails.userId))
      dispatch(fetchShoppingCartProducts(userDetails.userId))
      dispatch(fetchSaveForLater(userDetails.userId))
      dispatch(fetchOrders(userDetails.userId))
      dispatch(getAddress(userDetails.userId))
    } else {
      dispatch(fetchShoppingCartProducts(''))
      dispatch(fetchWishlistProducts(''))
    }
  }, [isUserLoggedIn, userDetails])

  useEffect(() => {
    const category = allCategories.find((category) => category.id === homePageData.specialCategory)
    if (category) {
      if (Object.entries(homePageData).length) {
        if (homePageData.specialCategory) {
          setNavigation(navigation.map((categ) => {
            if (categ.type === 'special') {
              categ.href = `/en-uk/category/${homePageData.specialCategory}`
              categ.name = category.name
            }
            return categ
          }))
        } else {
          setNavigation(navigation.filter((categ) => categ.type !== 'special'))
        }
      }
    }
  }, [homePageData, allCategories])


  return (
    <Disclosure as="nav" className="bg-white shadow z-50 top-0 lg:top-12 fixed w-full h-20">
      {({ open }) => (
        <>
          <div className="max-w-full mx-auto px-2 flex w-full h-full sm:px-4 lg:px-8">
            <div className="flex justify-center w-full">
              <div className="flex px-2 lg:px-0">
                <div className="flex-shrink-0 flex items-center cursor-pointer" onClick={() => navigate('/en-uk')}>
                  <img className="block lg:hidden h-8 w-auto" src="https://www.giftrealm.co.uk/images/logo.png" alt="Gift Realm" />
                  <img className="hidden lg:block h-8 w-auto" src="https://www.giftrealm.co.uk/images/logo.png" alt="Gift Realm" />
                </div>
                <div className="relative hidden lg:ml-6 h-full lg:flex lg:space-x-8">
                  <div className="flex space-x-4 2xl:space-x-8 h-full items-center justify-center">
                    {navigation.map((item, idx) => (
                      <Link
                        key={item.name}
                        to={item.href}
                        className={[
                          item.current ? 'border-gray-900 text-gray-900' : 'text-gray-900 border-white hover:border-gray-700 before:left-0 transition-all ease-linear duration-300',
                          'px-1 py-2  text-base tracking-wider text-start font-medium h-full flex items-center border-b-2'
                        ]}
                        aria-current={item.current ? item.name : undefined}
                        onMouseEnter={() => handleMouseOver(idx)}
                        onMouseLeave={() => handleMouseLeft(idx)}
                      >
                        {item.name !== 'Special Category' && <span className={[item.hidden ? 'hidden xl:flex max-xl:bg-red-400' : 'bg-green-300', item.type === 'special' ? 'px-2.5 py-0.5 rounded-2xl animated' : '' ]}>
                          {item.name}
                        </span>}
                      </Link>
                    ))}
                  </div>
                  {showDropdown && <div className='absolute top-20 -left-12' onMouseOver={() => setShowDropdown(true)} onMouseLeave={() => setShowDropdown(false)}>
                    <CategoryDropdown itemSelected={isItemSelected} />
                  </div>}
                </div>
              </div>
              <div className="flex-1 flex items-center justify-center px-2 lg:ml-6 lg:justify-end">
                <div className="max-w-lg w-full lg:max-w-xs">
                  <div className="relative">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <MagnifyingGlassIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                    </div>
                    <input id="search" name="search" className="block w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md leading-5 bg-white placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 sm:text-sm" placeholder="Search" type="search" onChange={(e) => searchQuery(e)} value={query} />
                    {searchDropdown && <div className='h-auto w-full bg-white border border-gray-300 rounded-b-md absolute'>
                      <ul className='divide-y'>
                        {
                          filteredSearchList.map((searchList) => (
                            <li key={searchList.id} className='px-4 py-2 text-start hover:bg-gray-200 cursor-pointer' onClick={() => navigateToCategoryPage(searchList)}>
                              {searchList.name}
                            </li>
                          ))
                        }
                      </ul>
                    </div>}
                  </div>
                </div>
              </div>
              <div className="flex items-center lg:hidden">
                {/* Mobile menu button */}
                <DisclosureButton className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </DisclosureButton>
              </div>
              <div className='flex md:ml-6 h-full items-center justify-center space-x-6'>
                <div className='relative max-xs:hidden'>
                  <span className='w-5 h-5 rounded-full flex justify-center items-center absolute -top-2 -right-2 text-sm text-white bg-[#b26b53]'>{wishlistProducts.length}</span>
                  <HeartIcon className='cursor-pointer h-8 w-8' onClick={() => navigate('/en-uk/wishlist')} />
                </div>
                <div className='relative max-xs:hidden'>
                  <span className='w-5 h-5 rounded-full flex justify-center items-center absolute -top-2 -right-2 text-sm text-white bg-[#b26b53]'>{shoppingCartProducts.length}</span>
                  <ShoppingBagIcon className='cursor-pointer h-8 w-8' onClick={() => navigate('/en-uk/shoppingCart')} />
                </div>
                <Menu as="div" className="relative hidden lg:block">
                  <div>
                    <MenuButton className="relative flex rounded-full text-sm">
                      <span className="absolute -inset-1.5" />
                      <UserIcon className='h-8 w-8 cursor-pointer' />
                    </MenuButton>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <MenuItems className="absolute right-0 z-10 mt-2 w-48 text-start origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {isUserLoggedIn && <MenuItem>
                        {({ active }) => (
                          <p
                            className={[active ? 'bg-gray-300' : '', 'text-start font-semibold tracking-wide block px-4 py-2 text-sm text-gray-700']}
                          >
                            {userDetails.userName}
                          </p>
                        )}
                      </MenuItem>}
                      {isUserLoggedIn && <MenuItem>
                        {({ active }) => (
                          <Link
                            to="/en-uk/orders"
                            className={[active ? 'bg-gray-300' : '', 'text-start hover:bg-gray-300 font-semibold tracking-wide block px-4 py-2 text-sm text-gray-700']}
                          >
                            Orders
                          </Link>
                        )}
                      </MenuItem>}
                      <MenuItem>
                        {({ active }) => (
                          <button
                            type='button'
                            onClick={handleLogin}
                            className={[active ? 'bg-gray-300' : '', 'block w-full text-start hover:bg-gray-300 font-semibold tracking-wide px-4 py-2 text-sm text-gray-700']}
                          >
                            {isUserLoggedIn ? 'Sign Out' : 'SignIn / SignUp'}
                          </button>
                        )}
                      </MenuItem>
                    </MenuItems>
                  </Transition>
                </Menu>
              </div>

            </div>
          </div>
          {showLoginModal && <Login closeModal={() => setShowLoginModal(false)} />}
          <DisclosurePanel className="lg:hidden bg-white border-b-2 border-gray-300">
            <div className="flex flex-col text-start space-y-4 2xl:space-x-8 h-full items-start justify-start">
              <span className='text-xl text-gray-900 font-medium ml-2'>Categories</span>
              {mobileNavigations.map((item, idx) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className={[
                    'text-gray-900 w-full transition-all ease-linear duration-300 px-8 py-1 text-base tracking-wider font-medium h-full flex items-start border-b-2'
                  ]}
                >
                  {item.name}
                </Link>
              ))}
            </div>
            <div className="pb-3 border-gray-200">
              <span className='text-xl text-gray-900 font-medium ml-2 flex mt-2'>Settings</span>
              <div className="mt-3 divide-y-2 space-y-1 text-start">
                {/* <DisclosureButton as="a" href="#" className="block px-8 py-2 text-base font-medium text-gray-800">Your Profile</DisclosureButton> */}
                <DisclosureButton as="a" href="/en-uk/orders" className="block px-8 py-2 text-base font-medium text-gray-800">Your Orders</DisclosureButton>
                <DisclosureButton as="a" onClick={handleLogin} className="block px-8 py-2 text-base font-medium text-gray-800"><span>{isUserLoggedIn ? 'Sign Out' : 'SignIn / SignUp'}</span></DisclosureButton>
              </div>
            </div>
          </DisclosurePanel>
        </>
      )
      }
    </Disclosure >
  )
}
