import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import { fetchLoggedInStatus } from '../stores/UserSlice';
import {fetchOrders} from '../stores/OrderSlice'
import { MdOutlineCurrencyPound } from "react-icons/md";
import {ArrowRightIcon} from "@heroicons/react/24/outline"
import { BlobProvider } from '@react-pdf/renderer'
import Invoice from '../components/Invoice'
import { calculateShippingInfo } from '../helper';


export const PaymentSuccessfull = () => {

    const location = useLocation()
    const dispatch = useDispatch()

    const allProducts = useSelector((state) => state.product.products)
    const userDetails = useSelector((state) => state.user.userDetails)
    const loggedInStatus = useSelector((state) => state.user.loggedInStatus)
    const orders = useSelector((state) => state.orders.orders)
    const [orderedProducts, setOrderedProducts] = useState([])
    const [orderDetails, setOrderDetails] = useState([])
    const [addressDetails, setAddressDetails] = useState({})
    const [paymentDetails, setPaymentDetails] = useState({})
    const [totalBill, setTotalBill] = useState({subtotal: '0', shippingEstimate: '0'})

    const getDeliveryDate = (date) => {
      const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      const day = new Date(date).getDay()
      const month = new Date(date).getMonth()
      return `${new Date(date).getDate()} ${months[month]} ${new Date(date).getFullYear()}, ${weekDays[day - 1]}`
    }

    const calculateTotalBill = (filteredProducts) => {
      let discountPrice = 0
      let price = 0
      let totalProductWeight = 0
      const info = {subtotal: 0, shippingEstimate: 0}
      filteredProducts.forEach((product) => {
        const selectedProduct = allProducts.find((pro) => pro.productId === product.productId)
        if (!selectedProduct) return
        price += (parseFloat(product.productPrice) * parseInt(product.quantity))
        discountPrice += (parseFloat(product.productDiscount) * parseInt(product.quantity))
        totalProductWeight += (parseFloat(selectedProduct.productWeight) * parseInt(product.quantity))
      })
      info.subtotal = parseFloat(price).toFixed(2)
      info.shippingEstimate = calculateShippingInfo(totalProductWeight)
      setTotalBill(info)
    }

    const setOrderList = (products) => {
      const b = products.split(',')
      b.pop()
      const pro = b.map((product) => {
          const c = product.split('|')
          const selectedProduct = allProducts.filter((p) => p.productId === c[0])
          if (selectedProduct.length > 0) {
              return {
                  productId: c[0],
                  productPrice: c[1],
                  quantity: c[2],
                  productImages: selectedProduct[0].productImages,
                  productDescription: selectedProduct[0].productShortDescription,
                  productName: selectedProduct[0].productName
              }
          }
          return "";
      })
      calculateTotalBill(pro)
      setOrderedProducts(pro)
    }

    const getExpiryInfo = (expiry) => {
      if (!expiry) return ""
      const expiryData = expiry.split(' / ')
      const month = expiryData[0].length === 1 ? `0${expiryData[0]}` : expiryData[0]
      const year = expiryData[1].slice(2)
      return `${month} / ${year}`
    }

    const sendRecieptData = (id) => {
      if (!id) return
      const order = orders.find((data) => data.orderId === id)
      const orderedProduct = order.productId.split(',')
      let totalProductWeight = 0
      orderedProduct.pop()
      const products = orderedProduct.map((product) => {
          const product_info = product.split('|')
          const selectedProduct = allProducts.find((prod) => prod.productId === product_info[0])
          if (!selectedProduct) return "";
          totalProductWeight += (parseFloat(selectedProduct.productWeight) * parseInt(product_info[2]))
          return {
              productId: product_info[0],
              productPrice: product_info[1],
              quantity: product_info[2],
              productImages: selectedProduct.productImages,
              productDescription: selectedProduct.productLongDescription,
              productName: selectedProduct.productName,
              productCategoryId: selectedProduct.productCategoryId
          }
      })
      const productInfo = products.map((product) => {
        return {
          id: product.productId,
          desc: product.productName,
          qty: product.quantity,
          price: product.productPrice,
        }
      })
      return {
        id: id,
        invoice_no: setInvoiceNo(order.invoiceNo),
        address: order.deliveryAddress && order.deliveryAddress.length > 0 && `${order.deliveryAddress[0].fullName} \n ${order.deliveryAddress[0].firstAddressLine} \n ${order.deliveryAddress[0].secondAddressLine} \n ${order.deliveryAddress[0].pincode}`,
        date: getDeliveryDate(order.date),
        items: productInfo,
        shippingCharge: calculateShippingInfo(totalProductWeight)
      }
  }

    const setInvoiceNo = (invoiceNo) => {
      const len = 10 - (invoiceNo.length + 3)
      let invoice = 'GRI'
      for (let i = 0 ; i <= len; i++) {
        invoice += '0'
      }
      invoice += invoiceNo
      return invoice
    }

    useEffect(() => {
      if (!loggedInStatus) dispatch(fetchLoggedInStatus())
      else if (!orders.length > 0) {
        dispatch(fetchOrders(userDetails.userId))
      } else {
        const id = location.search.split('?orderId=')[1] || ""
        const orderDetail = orders.find((order) => order.orderId === id && order.userId === userDetails.userId )
        setOrderDetails({ ...orderDetail })
        setOrderList(orderDetail.productId)
        setAddressDetails({ ...orderDetail.deliveryAddress[0] })
        setPaymentDetails({ ...JSON.parse(orderDetail.paymentInformation) })
      }
    }, [allProducts, fetchLoggedInStatus, orders])

  return (
    <div>
      <main className="relative lg:min-h-full">
    <div className="h-80 overflow-hidden lg:absolute lg:w-1/2 lg:h-full lg:pr-4 xl:pr-12">
      <img src="https://www.giftrealm.co.uk/images/confirmationImage.jpg" alt="Confirmation Image" className="h-full w-full object-center object-cover" />
    </div>

    <div>
      <div className="max-w-2xl mx-auto py-16 px-4 sm:px-6 sm:py-24 lg:max-w-[90rem] lg:px-8 lg:py-32 lg:pt-40 lg:grid lg:grid-cols-2 lg:gap-x-8 xl:gap-x-24">
        <div className="lg:col-start-2">
          <h1 className="text-sm font-medium text-indigo-600">Payment successful</h1>
          <p className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">Thanks for ordering</p>
          <p className="mt-2 text-base text-gray-500">We appreciate your order, we’re currently processing it. So hang tight and we’ll send you confirmation very soon!</p>

          <div className="flex justify-between">
            <dl className="mt-16 text-sm font-medium text-start">
              <dt className="text-gray-900">Order number</dt>
              <dd className="mt-2 text-indigo-600">{orderDetails.orderId || ""}</dd>
            </dl>

            <dl className="mt-16 text-sm font-medium text-end">
              <dt className="text-gray-900 mb-2">Invoice number</dt>
              {/* <dd className="mt-2 text-indigo-600 cursor-pointer">{orderDetails.invoiceNo && setInvoiceNo(orderDetails.invoiceNo)}</dd> */}
              {orderDetails.orderId && <BlobProvider document={<Invoice reciept_data={sendRecieptData(orderDetails.orderId)} />}>
                  {({ url, blob }) => (
                      <Link to={url} target="_blank" className=' text-indigo-600 cursor-pointer'>
                          {orderDetails.invoiceNo && setInvoiceNo(orderDetails.invoiceNo)}
                      </Link>
                  )}
              </BlobProvider>}
            </dl>
          </div>

          <ul className="mt-6 text-sm font-medium text-gray-500 border-t border-gray-200 divide-y divide-gray-200">
            {
                orderedProducts.length > 0 && orderedProducts.map((product, index) => {
                    return (
                        <li key={product.productId || index} className="flex py-6 space-x-6 text-start">
                          <img src={product.productImages && product.productImages.length > 0 && product.productImages[0].imageSrc} alt={product.productImages && product.productImages.length > 0 && product.productImages[0].imageAlt} className="flex-none w-24 h-24 bg-gray-100 rounded-md object-center object-cover" />
                          <div className="flex-auto space-y-1">
                              <h3 className="text-gray-900">
                              <Link to="/en-uk/products">{ product.productName || "" }</Link>
                              </h3>
                              <p>{ product.productDescription || "" }</p>
                              <p className="flex items-center"><MdOutlineCurrencyPound className="w-4 h-4"/>{ product && `${product.productPrice} x ${product.quantity}` }</p>
                          </div>
                          <p className="flex font-medium h-fit text-gray-900 items-center"><MdOutlineCurrencyPound className="w-4 h-4"/>{ (parseFloat(product.productPrice) * parseInt(product.quantity) || 0).toFixed(2) }</p>
                        </li>
                    )
                })
            }
          </ul>

          <dl className="text-sm font-medium text-gray-500 space-y-6 border-t border-gray-200 pt-6">
            <div className="flex justify-between">
              <dt>Subtotal</dt>
              <dd className="text-gray-900 flex items-center"><MdOutlineCurrencyPound className="w-4 h-4"/>{totalBill.subtotal || 0}</dd>
            </div>

            <div className="flex justify-between">
              <dt>Shipping</dt>
              <dd className="text-gray-900 flex items-center"><MdOutlineCurrencyPound className="w-4 h-4"/>{totalBill.shippingEstimate || 0}</dd>
            </div>

            <div className="flex items-center justify-between border-t border-gray-200 text-gray-900 pt-6">
              <dt className="text-base">Total</dt>
              <dd className="text-base flex items-center"><MdOutlineCurrencyPound className="w-5 h-5"/>{`${parseInt(orderDetails.orderPrice) / 100 || 0}`}</dd>
            </div>
          </dl>

          <dl className="mt-16 grid grid-cols-2 gap-x-4 text-sm text-gray-600">
            <div className="text-start">
              <dt className="font-medium text-gray-900">Shipping Address</dt>
              <dd className="mt-2">
                <address className="not-italic">
                  <span className="block">{addressDetails.fullName || ""}</span>
                  <span className="block">{addressDetails.firstAddressLine || ""}</span>
                  <span className="block">{addressDetails.secondAddressLine && `${addressDetails.secondAddressLine}, ON ${addressDetails.pincode}`}</span>
                </address>
              </dd>
            </div>
            <div className="text-end">
              <dt className="font-medium text-gray-900">Payment Information</dt>
              <dd className="mt-2 space-y-2 sm:flex sm:space-y-0 sm:space-x-4">
                <div className="flex-auto">
                  <p className="text-gray-900">{`Ending with ${paymentDetails.last4 || ""}`}</p>
                  <p>{`Expires ${getExpiryInfo(paymentDetails.expires)}`}</p>
                </div>
              </dd>
            </div>
          </dl>

          <div className="mt-16 border-t border-gray-200 py-6 text-right">
            <Link to="/en-uk/" className="text-sm font-medium text-indigo-600 hover:text-indigo-500 flex justify-end gap-x-1 items-center">Continue Shopping<ArrowRightIcon className="h-4 w-4"/></Link>
          </div>
        </div>
      </div>
    </div>
  </main>
    </div>
  )
}
