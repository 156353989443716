import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {ArrowRightIcon} from "@heroicons/react/24/outline"
import { setCurrentCategory } from '../stores/CategorySlice'
import {useNavigate} from 'react-router-dom'

export const CategoryDropdown = ({itemSelected}) => {

    const allCategories = useSelector((state) => state.category.categories)
    const allProducts = useSelector((state) => state.product.products)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [categories, setCategories] = useState(allCategories)

    const changeCurrentCategory = (category) => {
        dispatch(setCurrentCategory(category))
        navigate(`/en-uk/category/${category.id}`)
        itemSelected()
    }

    const [selectedCategory, setSelectedCategory] = useState(allCategories[0])

    useEffect(() => {
        const abc = allCategories.map((category) => {
            const products = allProducts.filter((product) => product.productCategoryId === category.id)
            return {...category, totalProducts: products.length}
        })
        setCategories(abc)
    }, [allCategories, allProducts])

  return (
    <div className='h-full min-w-[50rem] py-5 rounded-lg border grid grid-cols-2 bg-white divide-x-2 divide-gray-100'>
        <div className='px-5'>
            <ul className='flex flex-col py-3 text-start'>
                {
                    categories.map((category) => (
                        <li className='text-black flex group border-b border-gray-300 justify-between my-2 p-2 cursor-pointer hover:bg-gray-100' key={category.id} onMouseOver={() => setSelectedCategory(category)} onClick={() => changeCurrentCategory(category)}>
                            <span>
                                {category.name}
                                <span className='mx-4 text-gray-600 underline'>{category.totalProducts} items</span>
                            </span>
                            <ArrowRightIcon className='w-5 h-5 text-gray-500 group-hover:text-gray-800'/>
                        </li>
                    ))
                }
            </ul>
        </div>
        <div className='px-5'>
            <img src={selectedCategory ? selectedCategory.imageSrc : ""} alt={selectedCategory ? selectedCategory.imageAlt : ""} className='w-fit h-96 object-fill' />
        </div>
    </div>
  )
}
