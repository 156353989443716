import { Fragment, useState, useEffect } from 'react'
import { Combobox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

export default function ComboBox({items, selectedItem, displayItem, placeholder, filterSearch}) {
  const [people, setPeople] = useState([])
  const [query, setQuery] = useState('')

  useEffect(() => {
    setPeople(items)
  }, [items])

  const changed = (item) => {
    selectedItem(item)
  }

  useEffect(() => {
    filterSearch(query)
  }, [query])

  return (
    <div className="w-full">
      <Combobox value={displayItem} onChange={changed}>
        <div className="relative focus:outline-none">
          <div className="relative p-0.5 ring-1 ring-gray-300 w-full cursor-default overflow-hidden rounded-md bg-white text-left focus:outline-none sm:text-sm">
            <Combobox.Input
              className="w-full py-3 pl-3 pr-10 text-sm leading-5 text-gray-900 focus:outline-none"
              displayValue={displayItem}
              onChange={(event) => setQuery(event.target.value)}
              placeholder={placeholder || 'Select Category'}
            />
            <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
              <ChevronUpDownIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </Combobox.Button>
          </div>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
            afterLeave={() => setQuery('')}
          >
            <Combobox.Options className="absolute mt-1 max-h-52 w-full overflow-auto z-10 bg-white rounded-md py-1 text-base shadow-lg focus:outline-none sm:text-sm">
              {people.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  Nothing found.
                </div>
              ) : (
                people.map((person, idx) => (
                  <Combobox.Option
                    key={idx}
                    className={({ active }) =>
                      `relative cursor-pointer select-none py-2 pl-5 pr-4 ${active ? 'bg-gray-400 text-white' : 'text-gray-900'
                      }`
                    }
                    value={person}
                  >
                    {({ selected, active }) => (
                      <>
                        <span
                          className={`flex ml-5 truncate ${selected ? 'font-medium' : 'font-normal'
                            }`}
                        >
                          {person}
                        </span>
                        {selected ? (
                          <span
                            className={`absolute inset-y-0 left-0 flex items-center pl-3 ${active ? 'text-white' : 'tezt-[#4e0eff]'
                              }`}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </Transition>
        </div>
      </Combobox>
    </div>
  )
}
