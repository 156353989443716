import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { host } from "../APIRoutes";
import CheckoutForm from "./CheckoutForm";
import "../PaymentPage.css";
import { useSelector } from "react-redux";
import { LuLoader2 } from "react-icons/lu";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your test publishable API key.
const stripePromise = loadStripe("pk_test_51P202aSHg4a8X3K7CscV33DXoi3VIM522T1MKluHqyJ6mnyYLHgfrDQ0isSBrfZLQNfqGcn3dQdEA52ieTTDxAUQ00YGTNJHdX");
// const stripePromise = loadStripe("pk_live_51OA8InIKBLSnnFfrtfBKNtXhg1U60jbLn76qTzWeM7h0FBC2mxZ3CmGYOKJ5xZyRijBWqtdZrYPZbdCmKH8De33p001Yyzmjca");

export default function PaymentPage({ paymentAmount }) {
  const [clientSecret, setClientSecret] = useState("");
  const selectedAddress = useSelector((state) => state.shoppingCart.selectedAddress)

  // Enable when shifting to live version
  useEffect(() => {
    async function call() {
      const postData = {
        type: 'createPaymentIntent',
        payment: `${parseInt((parseFloat(paymentAmount)).toFixed(2) * 100)}`,
        name: selectedAddress.fullName,
        line1: selectedAddress.firstAddressLine,
        line2: selectedAddress.secondAddressLine,
        postal_code: selectedAddress.pincode,
        country: 'GB'
      }
      // Create PaymentIntent as soon as the page loads
      await fetch(`${host}/payment_checkout`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(postData),
      })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret));
    }
    call()
  }, []);

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div className="w-full flex justify-center items-center">
      {clientSecret ? (
        // <span>ace</span>
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      ) : 
        // <div className="">
          <LuLoader2 className="w-10 h-10 text-gray-500 animate-spin" />
        // </div>
      }
    </div>
  );
}