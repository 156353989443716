import React, { useEffect, useRef, useState } from 'react'
import { MenuItems, MenuItem, Menu, MenuButton } from '@headlessui/react'
import { ChevronLeftIcon, ChevronRightIcon, ClockIcon, EllipsisVerticalIcon, InformationCircleIcon } from '@heroicons/react/24/outline'
import { CheckCircleIcon } from '@heroicons/react/24/solid'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { MdOutlineCurrencyPound } from "react-icons/md";
import Invoice from '../components/Invoice'
import { BlobProvider } from '@react-pdf/renderer'
import { fetchOrders } from '../stores/OrderSlice'
import { fetchAllProducts } from '../stores/ProductSlice'
import { ordersAPI, headers } from '../APIRoutes'
import axios from 'axios'
import { ProgressBar } from '../components/ProgressBar'
import { calculateShippingInfo } from '../helper'

export const Orders = () => {

    const orderss = useSelector((state) => state.orders.orders)
    const userDetails = useSelector((state) => state.user.userDetails)
    const products = useSelector((state) => state.product.products)
    const navigate = useNavigate()

    const orderRef = useRef()
    const dispatch = useDispatch()

    const [allOrders, setAllOrders] = useState([])
    const [orders, setOrders] = useState([])
    const [totalPages, setTotalPages] = useState(1)
    const [currentPage, setCurrentPage] = useState(1)
    const [loading, setLoading] = useState(false)

    const statusUpdates = {
        'Order Placed': ['Order Placed'],
        'Order Confirmed': ['Order Placed', 'Processing'],
        'Order Shipped': ['Order Placed', 'Processing', 'Shipped'],
        'Order Delivered': ['Order Placed', 'Processing', 'Shipped', 'Delivered'],
        'Order Cancelled': ['Order Placed', 'Cancelling Order'],
        'Return Inititated': ['Order Placed', 'Cancelling Order'],
        'Returned': ['Order Placed', 'Cancelling Order', 'Returned'],
        'Refund Initiated': ['Order Placed', 'Cancelling Order', 'Returned', 'Refund Initiated'],
        'Refunded': ['Order Placed', 'Cancelling Order', 'Returned', 'Refund Initiated', 'Refunded Successfully'],
    }

    const getDeliveryDate = (date) => {
        const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
        const day = new Date(date).getDay()
        const month = new Date(date).getMonth()
        return `${new Date(date).getDate()} ${months[month]} ${new Date(date).getFullYear()}, ${weekDays[day - 1]}`
    }

    const setInvoiceNo = (invoiceNo) => {
        const len = 10 - (invoiceNo.length + 3)
        let invoice = 'GRI'
        for (let i = 0; i <= len; i++) {
            invoice += '0'
        }
        invoice += invoiceNo
        return invoice
    }

    const sendRecieptData = (id) => {
        const order = allOrders.find((data) => data.orderId === id)
        let totalProductWeight = 0
        const productInfo = order.products.map((product) => {
            const pro = products.find((prod) => prod.productId === product.productId)
            totalProductWeight += (parseFloat(pro.productWeight) * parseInt(product.quantity))
            return {
                id: product.productId,
                desc: product.productName,
                qty: product.quantity,
                price: product.productPrice
            }
        })
        return {
            id: id,
            invoice_no: setInvoiceNo(order.invoiceNo),
            address: order.addressDetails && order.addressDetails.length > 0 && `${order.addressDetails[0].firstAddressLine}, ${order.addressDetails[0].secondAddressLine}, ${order.addressDetails[0].pincode}`,
            date: order.orderedDate,
            items: productInfo,
            shippingCharge: calculateShippingInfo(totalProductWeight)
        }
    }

    const returnOrder = async (order) => {
        const abc = allOrders.map((def) => { if (def.orderId === order.orderId) {def.status = 'Order Cancelled'} return def } )
        setAllOrders([...abc])
        const postData = {
            type: 'updateOrder',
            orderId: order.orderId,
            orderStatus: 'Order Cancelled',
            deliveryDate: order.deliveryDate,
            userId: userDetails.userId
        }
        const response = await axios.post(ordersAPI, JSON.stringify(postData), { headers })
        dispatch(fetchOrders(userDetails.userId))
    }

    function is15DaysAfter(givenDateString) {
        const givenDate = new Date(givenDateString);
        const currentDate = new Date();
        const timeDifference = currentDate - givenDate;
        const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
        return daysDifference >= 15;
    }

    const getReturnStatus = (deliveryDate, status) => {
        let returnStatus = ''
        if (status === 'Order Confirmed' || status === 'Order Shipped' || status === 'Order Placed' || status === 'Order Confirmed') {
            if (!is15DaysAfter(deliveryDate)) {
                if (status === 'Order Delivered') returnStatus = 'Return Order'
                else returnStatus = 'Cancel Order'
            }
        }
        
        return returnStatus
    }

    const setProgressPercentage = (status) => {
        const filter = statusUpdates[status]
        if (status === 'Order Confirmed' || status === 'Order Shipped' || status === 'Order Placed' || status === 'Order Delivered') {
            return `${(filter.length / 4) * 100}%`
        } else {
            return `${(filter.length / 5) * 100}%`
        }
    }

    const setProgressList = (status) => {
        let list = []
        if (status === 'Order Confirmed' || status === 'Order Shipped' || status === 'Order Placed' || status === 'Order Delivered') {
            list = [{'status': false, 'name': 'Order Placed'}, {'status': false, 'name': 'Processing'}, {'status': false, 'name': 'Shipped'}, {'status': false, 'name': 'Delivered'}]
        } else {
            list = [{'status': false, 'name': 'Order Placed'}, {'status': false, 'name': 'Cancelling Order'}, {'status': false, 'name': 'Returned'}, {'status': false, 'name': 'Refund Initiated'}, {'status': false, 'name': 'Refunded Successfully'}]
        }

        const filter = statusUpdates[status]
        list.forEach((progress) => {
            if (filter.includes(progress.name)) {
                progress['status'] = true
            }
        })

        return list
    }

    const getDeliveryStatus = (deliveryDate, status) => {
        if (status === 'Order Confirmed' || status === 'Order Shipped' || status === 'Order Placed' || status === 'Order Delivered') {
            if (deliveryDate === 'Estimated in 4-5 Working Days') return 'Delivery is estimated within 4-5 business days.'
    
            if (status === 'Order Delivered') return `Delivered on ${getDeliveryDate(deliveryDate)}`
            
            return `Expected delivery by ${getDeliveryDate(deliveryDate)}`
        } else if (status === 'Order Cancelled' || status === 'Return Initiated') {
            return `Pickup is anticipated within 2-3 business days. Kindly ensure that all deliverables are prepared for collection.`
        } else if (status === 'Returned' || status === 'Refund Initiated') {
            return `Your refund will be issued to the original payment method used for your purchase within 5-7 business days`
        }
    }

    const fetchData = () => {
        setLoading(true)
        if (orderss.length && products.length) {
            const a = orderss.map((order) => {
                const b = order.productId.split(',')
                b.pop()
                const pro = b.map((product) => {
                    const c = product.split('|')
                    const selectedProduct = products.filter((p) => p.productId === c[0])
                    if (selectedProduct.length > 0) {
                        return {
                            productId: c[0],
                            productPrice: c[1],
                            quantity: c[2],
                            productImages: selectedProduct[0].productImages,
                            productDescription: selectedProduct[0].productLongDescription,
                            productName: selectedProduct[0].productName,
                            productCategoryId: selectedProduct[0].productCategoryId
                        }
                    }
                    return "";
                })
                return {
                    orderId: order.orderId,
                    invoiceNo: order.invoiceNo,
                    deliveryDate: order.deliveryDate,
                    total: order.orderPrice,
                    status: order.orderStatus,
                    orderedDate: order.date,
                    products: pro,
                    addressDetails: order.deliveryAddress
                }
            })
            let totalPage = 1
            if (parseInt(a.length % 5) === 0) totalPage = a.length / 5
            else totalPage = parseInt(a.length / 5) + 1
            setTotalPages(totalPage)
            const startIndex = 5 * (currentPage - 1)
            const abc = a.slice(startIndex, startIndex + 5)
            setAllOrders([...abc])
            setOrders(a)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (orderss && orderss.length && products.length) {
            fetchData()
        } else if (!orderss || !orderss.length) dispatch(fetchOrders(userDetails.userId))
        else if (!products.length) dispatch(fetchAllProducts(''))
    }, [orderss, products])

    useEffect(() => {
        if (orderss.length && products.length) {
            fetchData()
        } else if (!orderss || !orderss.length) dispatch(fetchOrders(userDetails.userId))
        else if (!products.length) dispatch(fetchAllProducts(''))
    }, [])

    useEffect(() => {
        const startIndex = 5 * (currentPage - 1)
        const a = orders.slice(startIndex, startIndex + 5)
        setAllOrders(a)
    }, [currentPage, orders])

    return (
        <div className="bg-white mt-10 mb-32 mx-4 xs:mx-16 2xl:mx-32">
            <div className="py-16 sm:py-8 sm:pt-32">
                <div className="max-w-7xl">
                    <div className="text-start max-w-2xl lg:max-w-full">
                        <h1 className="text-2xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Orders {loading}</h1>
                        <p className="mt-2 text-base text-gray-500">Check the status of recent orders, manage returns, and discover similar products.</p>
                    </div>
                </div>

                <div className="mt-16">
                    <div className="">
                        <div className="space-y-20 sm:px-4 lg:max-w-full lg:px-0">
                            {allOrders.length > 0 ? allOrders.map((order) => {
                                return (
                                    <div key={order.orderId} className="bg-white border-gray-200 shadow-lg rounded-lg border" ref={orderRef}>
                                        <div className="flex items-center p-4 border-b border-gray-200 sm:p-6 sm:grid sm:grid-cols-4 sm:gap-x-6">
                                            <dl className="flex-1 grid grid-cols-2 gap-x-6 text-base sm:col-span-3 sm:grid-cols-3 lg:col-span-2">
                                                <div className='text-start'>
                                                    <dt className="font-medium text-gray-900">Invoice number</dt>
                                                    <dd className="mt-1 text-gray-500">
                                                        {setInvoiceNo(order.invoiceNo)}
                                                    </dd>
                                                </div>
                                                <div className="hidden sm:block text-start">
                                                    <dt className="font-medium text-gray-900">Order placed</dt>
                                                    <dd className="mt-1 text-gray-500">
                                                        <span>{getDeliveryDate(order.orderedDate)}</span>
                                                    </dd>
                                                </div>
                                                <div className='text-start'>
                                                    <dt className="font-medium text-gray-900">Total amount</dt>
                                                    <dd className="mt-1 font-medium text-gray-900 flex items-center">
                                                        <MdOutlineCurrencyPound className="w-5 h-5" />
                                                        {order.total / 100}
                                                    </dd>
                                                </div>
                                            </dl>

                                            <Menu as="div" className="relative flex justify-end lg:hidden">
                                                <div className="flex items-center">
                                                    <MenuButton className="-m-2 p-2 flex items-center text-gray-400 hover:text-gray-500">
                                                        <EllipsisVerticalIcon className="w-6 h-6" aria-hidden="true" />
                                                    </MenuButton>
                                                </div>

                                                <div>
                                                    <MenuItems className="absolute right-0 mt-6 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                        <div className="py-1 flex flex-col items-start divide-y-2 w-40 divide-gray-200">
                                                            <MenuItem>
                                                                <BlobProvider document={<Invoice reciept_data={sendRecieptData(order.orderId)} />}>
                                                                    {({ url, blob }) => (
                                                                        <Link to={url} target="_blank" className='flex items-center cursor-pointer justify-center bg-white py-2 px-2.5 text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none'>
                                                                            <span>View Invoice</span>
                                                                        </Link>
                                                                    )}
                                                                </BlobProvider>
                                                            </MenuItem>
                                                        </div>
                                                    </MenuItems>
                                                </div>
                                            </Menu>

                                            <div className="hidden lg:col-span-2 lg:flex lg:items-center lg:justify-end lg:space-x-4">
                                                <BlobProvider document={<Invoice reciept_data={sendRecieptData(order.orderId)} />}>
                                                    {({ url, blob }) => (
                                                        <Link to={url} target="_blank" className='flex items-center cursor-pointer justify-center bg-white py-2 px-2.5 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none'>
                                                            <span>View Invoice</span>
                                                        </Link>
                                                    )}
                                                </BlobProvider>
                                            </div>
                                        </div>

                                        <ul className="divide-y divide-gray-200">
                                            {
                                                order.products.map((product, index) => {
                                                    return (
                                                        <li key={index} className="p-4 sm:p-6">
                                                            <div className="flex items-center sm:items-start">
                                                                <div className="flex-shrink-0 w-20 h-20 bg-gray-200 rounded-lg overflow-hidden sm:w-40 sm:h-40">
                                                                    <img src={product.productImages && product.productImages.length > 0 && product.productImages[0].imageSrc} alt={product.productImages && product.productImages[0].imageAlt} className="w-full h-full object-center object-cover" />
                                                                </div>
                                                                <div className="flex-1 text-start ml-6 text-xl">
                                                                    <div className="font-medium text-gray-900 sm:flex sm:justify-between">
                                                                        <h5>
                                                                            {product.productName}
                                                                        </h5>
                                                                        <p className="mt-2 sm:mt-0 font-medium flex items-center">
                                                                            <MdOutlineCurrencyPound className="w-6 h-6" />
                                                                            {product.productPrice}
                                                                        </p>
                                                                    </div>
                                                                    <p className="hidden text-gray-500 text-base sm:block sm:mt-2 lg:w-11/12">
                                                                        {product.productDescription}
                                                                    </p>
                                                                    <p className="hidden text-gray-500 text-base sm:block sm:mt-2">
                                                                        Quantity: {product.quantity}
                                                                    </p>
                                                                </div>
                                                            </div>

                                                            <div className="mt-6 sm:flex sm:justify-between">
                                                                <div className="mt-6 border-t border-gray-200 pt-4 flex items-center space-x-4 divide-x divide-gray-200 text-base font-medium sm:mt-0 sm:ml-4 sm:border-none sm:pt-0">
                                                                    <div className="flex-1 flex justify-center">
                                                                        <Link to={`/en-uk/category/${product.productCategoryId}`} className="text-indigo-600 whitespace-nowrap hover:text-indigo-500">Similar products</Link>
                                                                    </div>
                                                                    <div className="flex-1 pl-4 flex justify-center">
                                                                        <Link to={`/en-uk/product/${product.productId}`} className="text-indigo-600 whitespace-nowrap hover:text-indigo-500">Buy again</Link>
                                                                    </div>
                                                                </div>

                                                            </div >
                                                        </li >
                                                    )
                                                })
                                            }
                                        </ul >
                                        <div className="grid grid-cols-12 justify-between w-full items-center p-4 border-t-2">
                                            <div className='col-span-12'>
                                                <div className='flex items-center'>
                                                    { (order.status === 'Order Confirmed' || order.status === 'Order Shipped' || order.status === 'Order Placed') ? <ClockIcon className='w-5 h-5 text-orange-500' /> :
                                                     order.status === 'Order Delivered' ? <CheckCircleIcon className="w-5 h-5 text-green-500" aria-hidden="true" /> : <InformationCircleIcon className='w-5 h-5 text-blue-500' />}
                                                    <p className="ml-2 text-base font-medium text-gray-500">
                                                        {getDeliveryStatus(order.deliveryDate, order.status)}
                                                    </p>
                                                </div>
                                                <ProgressBar progressList={setProgressList(order.status)} progressPercentage={setProgressPercentage(order.status)} />
                                            </div>
                                        </div>
                                        {getReturnStatus(order.deliveryDate, order.status) && <div className="w-full p-4 border-t-2">
                                            <div className='col-span-2 flex justify-end'>
                                                <div className="ml-2 text-base font-medium text-red-500 hover:text-red-600 cursor-pointer" onClick={() => returnOrder(order)}>
                                                    {getReturnStatus(order.deliveryDate, order.status)}
                                                </div>
                                            </div>
                                        </div>}
                                    </div >
                                )
                            }) : <div className='flex flex-col items-center justify-center'>
                                <h2 className='text-xl font-bold'>Oops!!! Your orders list is empty {loading}</h2>
                                <button className='border-2 hover:bg-indigo-600 hover:text-white hover:border-indigo-600 border-gray-400 w-fit p-3 rounded-lg mt-3' onClick={() => navigate('/en-uk')}>Continue Shopping</button>
                            </div>}
                        </div >
                    </div >
                </div >
                {allOrders.length > 0 && <div className='mt-16 pb-6 flex space-x-5 justify-end items-center'>
                    <button disabled={currentPage === 1} className={[currentPage === 1 ? 'cursor-not-allowed text-gray-400' : 'text-black']}>
                        <ChevronLeftIcon className='w-6 h-6' onClick={() => setCurrentPage(currentPage - 1)} />
                    </button>
                    <span>
                        {`Page ${currentPage} of ${totalPages}`}
                    </span>
                    <button disabled={currentPage === totalPages} className={[currentPage === totalPages ? 'cursor-not-allowed text-gray-400' : 'text-black']}>
                        <ChevronRightIcon className='w-6 h-6' onClick={() => setCurrentPage(currentPage + 1)} />
                    </button>
                </div>}
            </div >
        </div >
    )
}
