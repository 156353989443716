import { useState } from 'react'
import axios from 'axios'
import { host, headers } from '../APIRoutes'
import {v4 as uuidv4} from 'uuid'
import { showMessage } from '../helper'
import { LuLoader2 } from 'react-icons/lu';

export default function ContactUs() {
  const [contactData, setContactData] = useState({
    name: '',
    phoneNumber: '',
    mail: '',
    msg: ''
})

  const [loading, setLoading] = useState(false)

const handleChange = (event) => {
    setContactData({ ...contactData, [event.target.name]: event.target.value })
}

const submitMessage = async (event) => {
    try {
      setLoading(true)
        event.preventDefault();
        if (!Object.values(contactData).some((value) => !value)) {
            contactData['contactId'] = uuidv4()
            contactData['type'] = 'addContact'
            const response = await axios.post(`${host}/contact_data`, JSON.stringify(contactData), { headers })
            showMessage('We got your query', 'success')
        } else{
            showMessage('Please fill the required data', 'error')
        }
    } catch (error) {
        showMessage(error, 'error')
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="isolate bg-white px-6 py-32 sm:py-32 sm:mt-16 lg:px-8">
      <div
        className="absolute inset-x-0 top-[-10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[-20rem]"
        aria-hidden="true"
      >
      </div>
      <div className="mx-auto max-w-2xl text-center">
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Contact Us</h2>
        {/* <p className="mt-2 text-lg leading-8 text-gray-600">
          Contact Us for need
        </p> */}
      </div>
      <form onSubmit={submitMessage} className="mx-auto mt-16 max-w-xl sm:mt-20 text-start">
        <div className="flex flex-col gap-x-8 gap-y-6">
          <div>
            <label htmlFor="name" className="block ml-2 text-lg tracking-wider font-semibold leading-6 text-gray-900">
              Full name
            </label>
            <div className="mt-2.5">
              <input
                type="text"
                onChange={(e) => handleChange(e)}
                value={contactData.name}
                name="name"
                id="first-name"
                required
                autoComplete="given-name"
                className="block w-full text-lg rounded-md border-0 px-3.5 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 stext-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label htmlFor="mail" className="block ml-2 text-lg tracking-wider font-semibold leading-6 text-gray-900">
              Email
            </label>
            <div className="mt-2.5">
              <input
                type="email"
                onChange={(e) => handleChange(e)}
                value={contactData.mail}
                name="mail"
                id="mail"
                required
                autoComplete="email"
                className="block w-full text-lg rounded-md border-0 px-3.5 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label htmlFor="phoneNumber" className="block ml-2 text-lg tracking-wider font-semibold leading-6 text-gray-900">
              Phone number
            </label>
            <div className="relative mt-2.5">
              <input
                type="number"
                onChange={(e) => handleChange(e)}
                value={contactData.phoneNumber}
                name="phoneNumber"
                id="phoneNumber"
                required
                autoComplete="tel"
                className="block w-full text-lg rounded-md border-0 px-3.5 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
          <div>
            <label htmlFor="msg" className="block ml-2 text-lg tracking-wider font-semibold leading-6 text-gray-900">
              Message
            </label>
            <div className="mt-2.5">
              <textarea
                onChange={(e) => handleChange(e)}
                value={contactData.msg}
                name="msg"
                required
                id="msg"
                rows={4}
                className="block w-full text-lg rounded-md border-0 px-3.5 py-3.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>
        <div className="mt-20">
          <button
            type="submit"
            className="w-full flex items-center justify-center space-x-3 rounded-md bg-indigo-600 px-3.5 py-3.5 text-center text-lg tracking-wider font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            <span>Submit</span>
            {loading && <LuLoader2 className='w-5 h-5 animate-spin' />}
          </button>
        </div>
      </form>
    </div>
  )
}
