import { toast } from "react-toastify"

export const showMessage = (message, type) => {
    if (type === 'error') {
        toast.error(message, {
            position: "bottom-right",
            autoClose: 8000,
            draggable: true,
            theme: 'dark',
            pauseOnHover: true,
        });
        return;
    }
    toast.success(message, {
        position: "bottom-right",
        autoClose: 8000,
        draggable: true,
        theme: 'dark',
        pauseOnHover: true,
    });
}

export const shippingCharges = {
    '0.1': 0.85,
    '0.75': 1.55,
    '2': 3.19,
    '20': 4.69
}

export const fetchShippingInfo = (weight) => {
    if (weight > 0 && weight <= 0.1) return `${shippingCharges['0.1']}`
    if (weight > 0.1 && weight <= 0.75) return `${shippingCharges['0.75']}`
    if (weight > 0.75 && weight <= 2) return `${shippingCharges['2']}`
    if (weight > 2 && weight <= 20) return `${shippingCharges['20']}`
}

export const calculateShippingInfo = (productWeight) => {
    return productWeight > 20 ? (shippingCharges['20'] * (Math.floor(productWeight / 20)) + parseFloat(fetchShippingInfo(productWeight % 20))).toFixed(2) : fetchShippingInfo(productWeight)
}