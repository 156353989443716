import React from 'react'
import { Image, Text, View, Page, Document, StyleSheet } from '@react-pdf/renderer';
// import Banner from "../assets/images/01_homepage_candle_05_1920x.png";
// import logo from "../assets/images/return.png";

const Invoice = ({ reciept_data }) => {

    const styles = StyleSheet.create({
        page: {fontSize: 11,paddingTop: 20,paddingLeft: 40,paddingRight: 40,lineHeight: 1.5,flexDirection: 'column' },

        spaceBetween : {flex : 1,flexDirection: 'row',alignItems:'center',justifyContent:'space-between',color: "#3E3E3E" },

        titleContainer: {flexDirection: 'row',marginTop: 24},
        
        logo: { width: 90 },

        reportTitle: {  fontSize: 40,  textAlign: 'center' },

        addressTitle : {fontSize: 12, fontWeight: 'extrabold'}, 
        
        invoice : {fontWeight: 'black',fontSize: 18, color: 'black'},
        
        invoiceNumber : {fontSize: 12,fontWeight: 'bold'}, 
        
        address : { fontWeight: 400, fontSize: 10},
        
        theader : {marginTop : 20,fontSize : 10,fontStyle: 'bold',paddingTop: 4 ,paddingLeft: 7 ,flex:1,height:20,backgroundColor : '#DEDEDE',borderColor : 'whitesmoke',borderRightWidth:1,borderBottomWidth:1},

        theader2 : { flex:2, borderRightWidth:0, borderBottomWidth:1},

        tbody:{ fontSize : 9, paddingTop: 4 , paddingLeft: 7 , flex:1, borderColor : 'whitesmoke', borderRightWidth:1, borderBottomWidth:1},

        total:{ fontSize : 9, paddingTop: 4 , paddingLeft: 7 , flex:1.5, borderColor : 'whitesmoke', borderBottomWidth:1},

        tbody2:{ flex:2, borderRightWidth:1, }
        
    });

    const InvoiceTitle = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                {/* <Image style={styles.logo} src={Banner} /> */}
                <Text style={styles.reportTitle}>Gift Realm</Text>
            </View>
        </View>
    );

    const Address = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View>
                    <Text style={styles.invoice}>Invoice </Text>
                    <Text style={styles.invoiceNumber}>Invoice number: {reciept_data.invoice_no} </Text>
                </View>
                <View>
                    <Text style={styles.invoice}>Billing Address </Text>
                    <Text style={styles.addressTitle}>7, Ademola Odede, </Text>
                    <Text style={styles.addressTitle}>Ikeja,</Text>
                    <Text style={styles.addressTitle}>Lagos, Nigeria.</Text>
                </View>
            </View>
        </View>
    );

    const UserAddress = () => (
        <View style={styles.titleContainer}>
            <View style={styles.spaceBetween}>
                <View style={{maxWidth : 200}}>
                    <Text style={styles.invoice}>Delivery Address</Text>
                    <Text style={styles.addressTitle}>
                        {reciept_data.address}
                    </Text>
                </View>
                <View style={{maxWidth: 200}}>
                    <Text style={styles.invoice}>Ordered Date</Text>
                    <Text style={styles.addressTitle}>{reciept_data.date}</Text>
                </View>
            </View>
        </View>
    );

    const TableHead = () => (
        <View style={{ width:'100%', flexDirection :'row', marginTop:10}}>
            <View style={[styles.theader, styles.theader2]}>
                <Text >Items</Text>   
            </View>
            <View style={styles.theader}>
                <Text>Price</Text>   
            </View>
            <View style={styles.theader}>
                <Text>Qty</Text>   
            </View>
            <View style={styles.theader}>
                <Text>Amount</Text>   
            </View>
        </View>
    );

    const TableBody = () => (
        reciept_data.items.map((receipt)=>(
        <View key={receipt.id}>
            <View style={{ width:'100%', flexDirection :'row'}}>
                <View style={[styles.tbody, styles.tbody2]}>
                    <Text >{receipt.desc}</Text>   
                </View>
                <View style={styles.tbody}>
                    <Text>{`£ ${receipt.price}`} </Text>   
                </View>
                <View style={styles.tbody}>
                    <Text>{receipt.qty}</Text>   
                </View>
                <View style={styles.tbody}>
                    <Text>{`£ ${(receipt.price * receipt.qty).toFixed(2)}`}</Text>   
                </View>
            </View>
        </View>
        ))
    );

    const ShippingTotal = () => (
        <View style={{ width:'100%', flexDirection :'row'}}>
            <View style={styles.total}>
                <Text></Text>   
            </View>
            <View style={styles.total}>
                <Text> </Text>   
            </View>
            <View style={styles.tbody}>
                <Text>Shipping Charge</Text>   
            </View>
            <View style={styles.tbody}>
                <Text>
                    {`£ ${reciept_data.shippingCharge}`}
                </Text>  
            </View>
        </View>
    );

    const TableTotal = () => (
        <View style={{ width:'100%', flexDirection :'row'}}>
            <View style={styles.total}>
                <Text></Text>   
            </View>
            <View style={styles.total}>
                <Text> </Text>   
            </View>
            <View style={styles.tbody}>
                <Text>Total</Text>   
            </View>
            <View style={styles.tbody}>
                <Text>
                    {`£ ${(parseFloat(reciept_data.items.reduce((sum, item)=> sum + (item.price * item.qty), 0)) + parseFloat(reciept_data.shippingCharge)).toFixed(2)}`}
                </Text>  
            </View>
        </View>
    );
            
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <InvoiceTitle  />
                <Address/>
                <UserAddress/>
                <TableHead/>
                <TableBody/>
                <ShippingTotal/>
                <TableTotal/>
            </Page>
        </Document>
    )
}
export default Invoice