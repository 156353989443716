import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { headers, host, ordersAPI, usersAPI } from '../APIRoutes';
import axios from "axios";
import { addOrder, updateOrder } from "../stores/OrderSlice"
import { updateProduct } from "../stores/ProductSlice"
import { removeCartProduct } from "../stores/ShoppingCart";


export default function CheckoutForm() {
  const stripe = useStripe();
  const elements = useElements();

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const shoppingCartProducts = useSelector((state) => state.shoppingCart.shoppingCartProducts)
  const selectedAddress = useSelector((state) => state.shoppingCart.selectedAddress)
  const allProducts = useSelector((state) => state.product.products)
  const userDetails = useSelector((state) => state.user.userDetails)
  const loggedInStatus = useSelector((state) => state.user.loggedInStatus)

  /**
   * Function to filter ordered products from all product list
   * @returns ordered product list
   */
  const setProducts = () => {
    const cartIds = shoppingCartProducts.map((product) => product.productId)
    let filteredProducts = allProducts.filter((product) => cartIds.includes(product.productId))
    filteredProducts = filteredProducts.map((product) => {
      const copyProductData = { ...product }
      const quantity = shoppingCartProducts.find((prod) => prod.productId === product.productId).quantity
      copyProductData.productStock = parseInt(copyProductData.productStock) - (parseInt(quantity) || 1)
      dispatch(updateProduct(copyProductData))
      return ({
        ...product,
        quantity: quantity || "1"
      })
    })
    return filteredProducts
  }

  /**
   * Function to save the order details in the database after successfull payment
   * 
   * @param {Object} paymentIntent 
   * @returns {Promise<string>} - OrderId of the saved order
   */
  async function savePaymentDetails(paymentIntent) {
    shoppingCartProducts.forEach((product) => dispatch(removeCartProduct(product, loggedInStatus)))
    const response = await axios.post(`${host}/payment_checkout`, JSON.stringify({ type: 'verifyPayment', id: paymentIntent.payment_method }), { headers })
    const products = setProducts()
    let addressId = selectedAddress.addressId || ""
    if (addressId === 'new') {
      const deliveryAddress = {
        addressId: uuidv4(),
        userId: userDetails.userId,
        fullName: paymentIntent.shipping.name,
        phoneNumber: selectedAddress.phoneNumber,
        firstAddressLine: paymentIntent.shipping.address.line1,
        secondAddressLine: paymentIntent.shipping.address.line2,
        landMark: selectedAddress.landMark,
        pincode: paymentIntent.shipping.address.postal_code
      }
      addressId = deliveryAddress.addressId
      const postdata = {
        type: 'addAddress',
        ...deliveryAddress
      }
      await axios.post(usersAPI, postdata, { headers })
    }
    let productString = ""
    products.forEach((product) => productString += `${product.productId}|${product.productDiscount || product.productPrice}|${product.quantity},`)
    productString.slice(0, productString.length - 1)
    const paymentInformation = {
      name: paymentIntent.shipping.name,
      paymentMethod: "Card",
      ...response.data.cardDetails
    }
    const data = {
      orderId: uuidv4(),
      paymentIntent: paymentIntent.id,
      userId: userDetails.userId,
      productId: productString,
      orderStatus: 'Order Placed',
      orderPrice: paymentIntent.amount.toString(),
      deliveryAddress: addressId,
      paymentInformation: JSON.stringify(paymentInformation),
      deliveryDate: 'Estimated in 4-5 Working Days'
    }

    const postData = {
      type: 'addOrder',
      ...data
    }

    const resp = await axios.post(ordersAPI, JSON.stringify(postData), { headers });

    return data.orderId
  }

  /**
   * Function to handle different types of after payment conditions
   *  
   * @param {Event} e 
   */
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    setIsLoading(true);

    const { error, paymentIntent } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required'
    });

    let id = ""

    switch (paymentIntent.status) {
      case "succeeded":
        id = await savePaymentDetails(paymentIntent)
        setMessage("Payment succeeded!");
        break;
      case "processing":
        setMessage("Your payment is processing.");
        break;
      case "requires_payment_method":
        setMessage("Your payment was not successful, please try again.");
        break;
      default:
        setMessage("Something went wrong.");
        break;
    }

    if (paymentIntent.status === 'succeeded') {
      const postData = {
        type: 'getOrders',
        userId: userDetails.userId
      }
      const response = await axios.post(ordersAPI, JSON.stringify(postData), { headers });
      const data = response.data;

      dispatch(updateOrder(data.data));
      navigate(`/en-uk/paymentConfirmation?orderId=${id}`)
    }
    else if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage(`An unexpected error occurred. ${error}`);
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <form id="payment-form" onSubmit={handleSubmit} className="sm:!w-[140%] md:!w-[180%] lg:!w-[60%] lg:min-w-[500px]">

      <PaymentElement id="payment-element" options={paymentElementOptions} className="" />
      <button disabled={isLoading || !stripe || !elements} id="submit" className="payment-button">
        <span id="button-text">
          {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
        </span>
      </button>
      {/* Show any error or success messages */}
      {message && <div id="payment-message">{message}</div>}
    </form>
  );
}